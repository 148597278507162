import React from 'react';
import styles from './RateView.module.css';

import ButtonIconText from '../button/ButtonIconText';
import ChangeOwnerModal from './modal/ChangeOwnerModal';

import { getCurrentResource } from '../../util/tools';
import { getButtonMode, getUserInfo } from '../../util/localStorage';
import { performApiRequest, QUERY_LOADGROUPSANDUSERS_API_URL } from '../../util/restapi';

import keyIcon from '../../assets/icons/png24x24/Key.png';

class ButtonChangeOwner extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.ButtonChangeOwner;
		this.rateView = props.holder;
		this.userInfo = getUserInfo();
		this.useIcons = getButtonMode() == 'icon';
		this.listUser = [];
		this.listGroup = [];
		this.state = {
			isModalOpen: false,
			listUser: [],
			listGroup: []
		}
		this.modalContext = {
			ids: [],
			saved: false,
			response: null
		}
	}
	
	changeOwner = async () => {
		this.modalContext.ids = this.props.holder.getSelectedIds();
		if( this.modalContext.ids.length == 0 ) {
			this.rateView.props.app.showMessage( {mainMessage: this.resource.msgNoRowSelected} );
			return;
		}	
	
		var response = await performApiRequest( QUERY_LOADGROUPSANDUSERS_API_URL );
		if( ! response.success ) {
			this.rateView.props.app.showResponse(response);
			return;
		}
		
		this.listUser = [{id: 0, login: ''}];
		for(let user of response.data.listUser) {
			this.listUser.push( user );
		}
		
		this.listGroup = [{id: 0, card: '', listUser: []}];
		for( let group of response.data.listGroup ) {
			this.listGroup.push( group );
		}
		this.modalContext.saved = false;
		this.setState({ isModalOpen: true, listUser: this.listUser, listGroup: this.listGroup });
	}
	closeModal = () => {
		this.setState({ isModalOpen: false });
		if( this.modalContext.saved ) {
			this.rateView.props.app.showResponse( this.modalContext.response, this.resource.msgOwnerChanged );
		}
	}
	
	render() {
		if( this.props.holder.props.rvOptions.hideButtonChangeOwner )
			return null;
		var allowed = this.userInfo.permission.superuser;
		if( allowed ) {
			return (
				<React.Fragment>
					<ButtonIconText 
						onClick={this.changeOwner}
						buttonType={'button'} useIcon={this.useIcons} styles={styles}
						text={this.resource.buttonChangeOwner} 
						tooltip={this.resource.buttonChangeOwnerTooltip} icon={keyIcon}
					/>
					<ChangeOwnerModal 
						isModalOpen={this.state.isModalOpen} 
						close={this.closeModal} 
						holder={this} modalContext={this.modalContext} />
				</React.Fragment>
			);
		} else {
			return (null);
		}
	}
}

export default ButtonChangeOwner;
