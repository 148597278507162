import React, { createRef } from 'react';
import styles from './RateView.module.css';

import ButtonIconText from '../button/ButtonIconText';
import UpdateFieldModal from './modal/UpdateFieldModal';

import { getCurrentResource } from '../../util/tools';
import { getButtonMode, getUserInfo, canPerformAction } from '../../util/localStorage';
import { ACTION_INSTANCE_UPDATE_FIELDS } from '../../util/consts/actions';

import updateFieldIcon from '../../assets/icons/png24x24/edit-m.png';

class ButtonFieldUpdate extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.ButtonFieldUpdate;
		this.rateView = props.holder;
		this.refModal = React.createRef();
		this.userInfo = getUserInfo();
		this.useIcons = getButtonMode() == 'icon';
		this.state = {
			isModalOpen: false,
		}
	}
	
	updateFields = () => {
		this.refModal.current.init( null );
		this.setState({ isModalOpen: true });
	}
	closeModal = () => {
		this.setState({ isModalOpen: false });
		var modal = this.refModal.current; 
		if( modal.tryUpdate ) {
			this.rateView.props.app.showResponse( modal.modalResponse, this.resource.msgUpdated );
		}
	}
	
	render() {
		if( this.props.holder.props.rvOptions.hideButtonFieldUpdate )
			return null;
		var holder = this.props.holder;
		var userInfo = holder.userInfo;
		if( ! canPerformAction(ACTION_INSTANCE_UPDATE_FIELDS, userInfo) )
			return null;
//		if( ! holder.formInfo.listFieldUpdateModel || holder.formInfo.listFieldUpdateModel.length == 0 )
//			return null;

		return (
			<React.Fragment>
				<ButtonIconText 
					onClick={this.updateFields}
					buttonType={'button'} useIcon={this.useIcons} styles={styles}
					text={this.resource.buttonUpdateField} 
					tooltip={this.resource.buttonUpdateFieldTooltip} icon={updateFieldIcon}
				/>
				<UpdateFieldModal ref={this.refModal} 
					isModalOpen={this.state.isModalOpen} 
					close={this.closeModal} 
					holder={this} rateView={this.props.holder} />
			</React.Fragment>
		);
	}
	
}

export default ButtonFieldUpdate;
