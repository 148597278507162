import React, { createRef } from 'react';

import Field from './Field';
import { FILE_WAS_SET, FILE_WAS_CLEARED } from './FieldFile';

import { IET_SET_VALUE, createEmptyFieldFileValue } from '../../util/realm/instance';
import { getCurrentResource, getDefinedItem } from '../../util/tools';

import iconSelect from '../../assets/icons/png24x24/Search.png';
import iconClear from '../../assets/icons/png24x24/edit-clear.png';

class FieldImage extends Field {

	fileRef = createRef();

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldImage;
		this.state.value = getDefinedItem(this, ['field', 'value'], createEmptyFieldFileValue());
	}

	handleInstanceChange = (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	
	handleFileSelected = (e) => {
		let files = e.target.files;
		if( files && files.length > 0 ) {
			let instance = this.props.instance; 
			let field = this.field;
			let value = {...field.value};
			let reader = new FileReader();
			reader.onload = function(event) {
				value.fileState = FILE_WAS_SET;
				value.fileBody = files[0];
				value.imageBinary = event.target.result;
				instance.setFieldValue( field, value );
			};

			instance.setFieldValue( this.field, null );
			reader.readAsDataURL(files[0]);
		}
	}
	clearFile = () => {
		let value = createEmptyFieldFileValue();
		if( this.field.value != FILE_WAS_SET ) {
			value.fileState = FILE_WAS_CLEARED;
		}
		this.props.instance.setFieldValue( this.field, value );
	}
	
	render() {
		
		if( this.field == null ) {
			return this.createDivUnknownField();
		}
		
		let value = this.state.value;
		
		let buttonSelectFileDisabled = this.props.readOnly;
		let imageValued = value && ( value.fileURL || value.fileState == FILE_WAS_SET)
		let buttonClearFileDisabled = this.props.readOnly || ! imageValued;
		
		let imageHtml = (
			<div style={{width: '100%', height: '200px', border: '1px solid black', backgroundColor: 'gray'}}></div>
		);
		if( value ) {
			if( value.fileState == FILE_WAS_SET )
				imageHtml = (
					<div style={{width: '100%'}}>
						<img src={value.imageBinary} style={{width: '100%'}} />
					</div>
				);
			else if( value.fileURL )
				imageHtml = (
					<div style={{width: '100%'}}>
						<img src={value.fileURL} style={{width: '100%'}} />
					</div>
				);
		}
		
		return (
			<div style={{display: 'flex', alignItems: 'start', width: '100%'}}>
				<div style={{minWidth: '80px'}}>
					<button
						onClick={e => this.fileRef.current.click()}
						disabled={buttonSelectFileDisabled}
						title={this.resource.buttonSelectImage}
					>
						<img src={iconSelect} />
					</button>
					<button style={{marginLeft: '5px'}}
						onClick={this.clearFile} 
						disabled={buttonClearFileDisabled}
						title={this.resource.buttonClearImage}
					>
						<img src={iconClear} />
					</button>
				</div>
				<div style={{marginLeft: '20px', flexGrow: '1'}}>
					<input ref={this.fileRef} type={'file'} style={{display: 'none'}} onChange={e => this.handleFileSelected(e)} />
					{imageHtml}
				</div>
			</div>
		);
	}
}

export default FieldImage;