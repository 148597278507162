import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';

import { getCurrentResource, getTwoDigitNumber } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { ACTION_EBOOK_BANK_IMPORT } from '../../util/consts/actions';
import { FORM_EBOOK_BANK_TABLE } from '../../util/consts/forms';
import { 
	performApiRequest, ACTION_EBOOK_BANK_IMPORT_API_URL 
} from '../../util/restapi';

import iconImport from '../../assets/icons/png24x24/get_message.png';

class EBookBankListPage extends Component {
	
	constructor(props) {
		super(props);
		this.refImportModal = React.createRef();
		this.refDownloadModal = React.createRef();

		this.resource = getCurrentResource().pages.tableform.EBookBankList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_EBOOK_BANK_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_EBOOK_BANK_TABLE,
			formTitle: this.resource.formTitle,
			paramCacheId: paramCacheId,
			transition: null,
			controlPanelTools: this.controlPanelTools(),
		};
	}
	controlPanelTools = () => {
		return (
			<div style={{display: 'flex', marginLeft: '20px'}}>
				<ControlPanelButtonImport holder={this} />
			</div>			
		);
	}
	importBanks = async () => {
		let response = await performApiRequest(ACTION_EBOOK_BANK_IMPORT_API_URL, {});
		if( response.success ) {
			this.props.app.showMessage({mainMessage: 'Success'});
		} else {
			this.props.app.showResponse(response)
		}
		
	}

	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app}  />
			</React.Fragment>
		)
	}
	
}

function ControlPanelButtonImport(props) {
	
	if( canPerformAction( ACTION_EBOOK_BANK_IMPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.importBanks}
				 title={'Import'} 
			>
				<img src={iconImport} />
			</button>			
		);
	} else {
		return null;
	}
}

const localStyles = {
	divHeader: {
		fontSize: '16pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divParameters: {
		marginTop: '10px',
		padding: '10px'
	},
	divWaiting: {
		textAlign: 'center',
		padding: '7px'
	},
	divButtons: {
		marginTop: '7px',
		padding: '10px',
		marginRight: '15px',
		textAlign: 'right'
	}
};

export default EBookBankListPage;
