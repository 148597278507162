import React, { Component, createRef } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import InfoMessage from './components/message/InfoMessage'
import { getCurrentResource } from './util/tools';
import { getUserInfo, canViewForm, getLanguage, getButtonMode } from './util/localStorage';
//import Message from './modal/message/Message';

import HelloPage from './pages/hello/HelloPage';
import BomMessagePage from './pages/message/BomMessagePage';
import ProfilePage from './pages/profile/ProfilePage';
import ElibriPage from './pages/elibri/ElibriPage';
import DbManagerPage from './pages/admin/DbManagerPage';
import SystemInfoPage from './pages/admin/SystemInfoPage';
import ConverterPage from './pages/converter/ConverterPage';
import BookSupplyPage from './pages/booksupply/BookSupplyPage';

import AuthorityAuthorPage from './pages/plain/AuthorityAuthorPage';
import AuthorityPublisherPage from './pages/plain/AuthorityPublisherPage';
import BookPage from './pages/plain/BookPage';
import BookProdPage from './pages/plain/BookProdPage';
import ClassifierPage from './pages/plain/ClassifierPage';
import ConfirmationPage from './pages/plain/ConfirmationPage';
import ContactPage from './pages/plain/ContactPage';
import DerivativePage from './pages/plain/DerivativePage';
import DigitalStorePage from './pages/plain/DigitalStorePage';
import EstimationPage from './pages/plain/EstimationPage';
import EventPage from './pages/plain/EventPage';
import EventPlainPage from './pages/plain/EventPlainPage';
import HonorPage from './pages/plain/HonorPage';
import LibraryPage from './pages/plain/LibraryPage';
import LocationPage from './pages/plain/LocationPage';
import MessagePage from './pages/plain/MessagePage';
import OfferPage from './pages/plain/OfferPage';
import OrderPage from './pages/plain/OrderPage';
import OrganizationPage from './pages/plain/OrganizationPage';
import ProcurementPage from './pages/plain/ProcurementPage';
import ProductPage from './pages/plain/ProductPage';
import ProviderPage from './pages/plain/ProviderPage';
import TopicPage from './pages/plain/TopicPage';
import UserGroupPage from './pages/plain/UserGroupPage';
import UserPage from './pages/plain/UserPage';

import AuthorityAuthorPlainPage from './pages/plain/AuthorityAuthorPlainPage';
import AuthorityPublisherPlainPage from './pages/plain/AuthorityPublisherPlainPage';
import BookPlainPage from './pages/plain/BookPlainPage';
import OfferPlainPage from './pages/plain/OfferPlainPage';
import ProductPlainPage from './pages/plain/ProductPlainPage';
import PlainEBookReportPage from './pages/plain/PlainEBookReportPage';
import PlainEBookSupplierPage from './pages/plain/PlainEBookSupplierPage';

import AuthorityAuthorListPage from './pages/tableform/AuthorityAuthorListPage';
import AuthorityPublisherListPage from './pages/tableform/AuthorityPublisherListPage';
import BookListPage from './pages/tableform/BookListPage';
import ClassifierListPage from './pages/tableform/ClassifierListPage';
import ConfirmationListPage from './pages/tableform/ConfirmationListPage';
import ContactListPage from './pages/tableform/ContactListPage';
import DerivativeListPage from './pages/tableform/DerivativeListPage';
import DigitalStoreListPage from './pages/tableform/DigitalStoreListPage';
import EBookBankListPage from './pages/tableform/EBookBankListPage';
import EBookPaymentListPage from './pages/tableform/EBookPaymentListPage';
import EBookReportListPage from './pages/tableform/EBookReportListPage';
import EBookReportItemListPage from './pages/tableform/EBookReportItemListPage';
import EBookSupplierListPage from './pages/tableform/EBookSupplierListPage';
import EBookTerminalListPage from './pages/tableform/EBookTerminalListPage';
import EstimationListPage from './pages/tableform/EstimationListPage';
import EventListPage from './pages/tableform/EventListPage';
import HonorListPage from './pages/tableform/HonorListPage';
import LibraryListPage from './pages/tableform/LibraryListPage';
import LocationListPage from './pages/tableform/LocationListPage';
import MessageListPage from './pages/tableform/MessageListPage';
import OfferListPage from './pages/tableform/OfferListPage';
import OrderListPage from './pages/tableform/OrderListPage';
import OrganizationListPage from './pages/tableform/OrganizationListPage';
import ProcurementListPage from './pages/tableform/ProcurementListPage';
import ProductListPage from './pages/tableform/ProductListPage';
import ProviderListPage from './pages/tableform/ProviderListPage';
import TopicListPage from './pages/tableform/TopicListPage';
import UserGroupListPage from './pages/tableform/UserGroupListPage';
import UserListPage from './pages/tableform/UserListPage';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import {
	ROOT_PATH,
	HELLO_PATH,
	ELIBRI_PATH,
	PROFILE_PATH,
	DBMANAGER_PATH,
	SYSINFO_PATH,

	AUTHORITY_AUTHOR_PATH,
	AUTHORITY_PUBLISHER_PATH,
	BOOK_PATH,
	BOOK_PROD_PATH,
	CLASSIFIER_PATH,
	CONFIRMATION_PATH,
	CONTACT_PATH,
	DERIVATIVE_PATH,
	DIGITAL_STORE_PATH,
	ESTIMATION_PATH,
	EVENT_PATH,
	HONOR_PATH,
	LIBRARY_PATH,
	MESSAGE_PATH,
	OFFER_PATH,
	ORDER_PATH,
	ORGANIZATION_PATH,
	LOCATION_PATH,
	PROCUREMENT_PATH,
	PRODUCT_PATH,
	PROVIDER_PATH,
	TOPIC_PATH,
	USER_GROUP_PATH,
	USER_PATH,
	
	AUTHORITY_AUTHOR_TABLE_PATH,
	AUTHORITY_PUBLISHER_TABLE_PATH,
	BOOK_TABLE_PATH,
	CLASSIFIER_TABLE_PATH,
	CONFIRMATION_TABLE_PATH,
	CONTACT_TABLE_PATH,
	DERIVATIVE_TABLE_PATH,
	DIGITAL_STORE_TABLE_PATH,
	EBOOK_BANK_TABLE_PATH,
	EBOOK_PAYMENT_TABLE_PATH,
	EBOOK_REPORT_TABLE_PATH,
	EBOOK_REPORT_ITEM_TABLE_PATH,
	EBOOK_SUPPLIER_TABLE_PATH,
	EBOOK_TERMINAL_TABLE_PATH,
	ESTIMATION_TABLE_PATH,
	EVENT_TABLE_PATH,
	HONOR_TABLE_PATH,
	LIBRARY_TABLE_PATH,
	MESSAGE_TABLE_PATH,
	OFFER_TABLE_PATH,
	ORDER_TABLE_PATH,
	ORGANIZATION_TABLE_PATH,
	LOCATION_TABLE_PATH,
	PROCUREMENT_TABLE_PATH,
	PRODUCT_TABLE_PATH,
	PROVIDER_TABLE_PATH,
	TOPIC_TABLE_PATH,
	USER_GROUP_TABLE_PATH,
	USER_TABLE_PATH,
	
	AUTHORITY_AUTHOR_PLAIN_PATH,
	AUTHORITY_PUBLISHER_PLAIN_PATH,
	BOOK_PLAIN_PATH,
	EBOOK_REPORT_PLAIN_PATH,
	EBOOK_SUPPLIER_PLAIN_PATH,
	OFFER_PLAIN_PATH,
	PRODUCT_PLAIN_PATH,
	
	CONVERTER_PATH,
	BOOKSUPPLY_PATH
} from './util/routes';

import {
	FORM_AUTHORITY_AUTHOR_TABLE,
	FORM_AUTHORITY_PUBLISHER_TABLE,
	FORM_BOOK_TABLE,
	FORM_CLASSIFIER_TABLE,
	FORM_CONFIRMATION_TABLE,
	FORM_CONTACT_TABLE,
	FORM_DERIVATIVE_TABLE,
	FORM_DIGITAL_STORE_TABLE,
	FORM_EBOOK_BANK_TABLE,
	FORM_EBOOK_PAYMENT_TABLE,
	FORM_EBOOK_REPORT_TABLE,
	FORM_EBOOK_REPORT_ITEM_TABLE,
	FORM_EBOOK_SUPPLIER_TABLE,
	FORM_EBOOK_TERMINAL_TABLE,
	FORM_ESTIMATION_TABLE,
	FORM_EVENT_TABLE,
	FORM_HONOR_TABLE,
	FORM_LIBRARY_TABLE,
	FORM_LOCATION_TABLE,
	FORM_MESSAGE_TABLE,
	FORM_OFFER_TABLE,
	FORM_ORDER_TABLE,
	FORM_ORGANIZATION_TABLE,
	FORM_PROCUREMENT_TABLE,
	FORM_PRODUCT_TABLE,
	FORM_PROVIDER_TABLE,
	FORM_TOPIC_TABLE,
	FORM_USER_GROUP_TABLE,
	FORM_USER_TABLE,

	FORM_AUTHORITY_AUTHOR_FT,
	FORM_AUTHORITY_PUBLISHER_FT,
	FORM_BOOK_FT,
	FORM_BOOK_PROD_FT,
	FORM_CLASSIFIER_FT,
	FORM_CONFIRMATION_FT,
	FORM_CONTACT_FT,
	FORM_DERIVATIVE_FT,
	FORM_DIGITAL_STORE_FT,
	FORM_ESTIMATION_FT,
	FORM_EVENT_FT,
	FORM_HONOR_FT,
	FORM_LIBRARY_FT,
	FORM_LOCATION_FT,	
	FORM_MESSAGE_FT,	
	FORM_OFFER_FT,
	FORM_ORDER_FT,
	FORM_ORGANIZATION_FT,
	FORM_PROCUREMENT_FT,
	FORM_PRODUCT_FT,
	FORM_PROVIDER_FT,
	FORM_TOPIC_FT,
	FORM_USER_FT,
	FORM_USER_GROUP_FT,
	
	FORM_AUTHORITY_AUTHOR_PLAIN, 
	FORM_AUTHORITY_PUBLISHER_PLAIN, 
	FORM_BOOK_PLAIN,
	FORM_EBOOK_REPORT_PLAIN,
	FORM_EBOOK_SUPPLIER_PLAIN,
	FORM_OFFER_PLAIN, 
	FORM_PRODUCT_PLAIN,
	
	FORM_DBMANAGER
} from './util/consts/forms';

class App extends Component {

	constructor(props) {
		super(props);
		this.refInfoMessage = React.createRef();
		this.state = {
			isModalMessageOpen: false,
			lang: getLanguage(),
			buttonMode: getButtonMode()
		}
	}

	showResponse = (response, defaultMessage, doAfterClose) => {
		this.refInfoMessage.current.showResponse(response, defaultMessage, doAfterClose);
	}
	/*
		message : {
			title
			mainMessage
			additionalMessage
			maxWidth
		}
	*/
	showMessage = ( message, doAfterClose ) => {
		this.refInfoMessage.current.showMessage(message, doAfterClose);
	}

	render () {
		let userInfo = getUserInfo();
		console.log('UserInfo', userInfo);
		return (
			<Router>
				<div className="App">
					<InfoMessage ref={this.refInfoMessage} />
					<Header />
					<Switch>
						<Route exact path={ROOT_PATH} render={() => <Redirect to={HELLO_PATH} />} />
						<AuthorizedRoute exact path={HELLO_PATH} component={props => <HelloPage {...props} />} app={this} />
						<AuthorizedRoute exact path={ELIBRI_PATH} component={props => <ElibriPage {...props} />} app={this} />
						<AuthorizedRoute exact path={PROFILE_PATH} component={props => <ProfilePage {...props} />} app={this} />

						<AuthorizedRoute exact path={`${BOOK_PATH}/:id`} 
							component={props => <BookPage {...props} />} 
							form={FORM_BOOK_FT} 
							app={this} 
						/>

						<AuthorizedRoute exact path={AUTHORITY_AUTHOR_TABLE_PATH} 
							component={props => <AuthorityAuthorListPage {...props} />}
							form={FORM_AUTHORITY_AUTHOR_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${AUTHORITY_AUTHOR_TABLE_PATH}/:param`} 
							component={props => <AuthorityAuthorListPage {...props} />}
							form={FORM_AUTHORITY_AUTHOR_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={AUTHORITY_PUBLISHER_TABLE_PATH} 
							component={props => <AuthorityPublisherListPage {...props} />} 
							form={FORM_AUTHORITY_PUBLISHER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${AUTHORITY_PUBLISHER_TABLE_PATH}/:param`} 
							component={props => <AuthorityPublisherListPage {...props} />} 
							form={FORM_AUTHORITY_PUBLISHER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={BOOK_TABLE_PATH} 
							component={props => <BookListPage {...props} />} 
							form={FORM_BOOK_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${BOOK_TABLE_PATH}/:param`} 
							component={props => <BookListPage {...props} />} 
							form={FORM_BOOK_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={CLASSIFIER_TABLE_PATH} 
							component={props => <ClassifierListPage {...props} />} 
							form={FORM_CLASSIFIER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${CLASSIFIER_TABLE_PATH}/:param`} 
							component={props => <ClassifierListPage {...props} />} 
							form={FORM_CLASSIFIER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={CONFIRMATION_TABLE_PATH} 
							component={props => <ConfirmationListPage {...props} />} 
							form={FORM_CONFIRMATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${CONFIRMATION_TABLE_PATH}/:param`} 
							component={props => <ConfirmationListPage {...props} />} 
							form={FORM_CONFIRMATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={CONTACT_TABLE_PATH} 
							component={props => <ContactListPage {...props} />} 
							form={FORM_CONTACT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${CONTACT_TABLE_PATH}/:param`} 
							component={props => <ContactListPage {...props} />} 
							form={FORM_CONTACT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={DERIVATIVE_TABLE_PATH} 
							component={props => <DerivativeListPage {...props} />} 
							form={FORM_DERIVATIVE_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${DERIVATIVE_TABLE_PATH}/:param`} 
							component={props => <DerivativeListPage {...props} />} 
							form={FORM_DERIVATIVE_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={DIGITAL_STORE_TABLE_PATH} 
							component={props => <DigitalStoreListPage {...props} />} 
							form={FORM_DIGITAL_STORE_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${DIGITAL_STORE_TABLE_PATH}/:param`} 
							component={props => <DigitalStoreListPage {...props} />} 
							form={FORM_DIGITAL_STORE_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_BANK_TABLE_PATH}`} 
							component={props => <EBookBankListPage {...props} />}
							form={FORM_EBOOK_BANK_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_PAYMENT_TABLE_PATH}`} 
							component={props => <EBookPaymentListPage {...props} />}
							form={FORM_EBOOK_PAYMENT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_BANK_TABLE_PATH}/:param`} 
							component={props => <EBookBankListPage {...props} />}
							form={FORM_EBOOK_BANK_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_REPORT_TABLE_PATH}`} 
							component={props => <EBookReportListPage {...props} />}
							form={FORM_EBOOK_REPORT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_REPORT_TABLE_PATH}/:param`} 
							component={props => <EBookReportListPage {...props} />}
							form={FORM_EBOOK_REPORT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_REPORT_ITEM_TABLE_PATH}`} 
							component={props => <EBookReportItemListPage {...props} />}
							form={FORM_EBOOK_REPORT_ITEM_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_REPORT_ITEM_TABLE_PATH}/:param`} 
							component={props => <EBookReportItemListPage {...props} />}
							form={FORM_EBOOK_REPORT_ITEM_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_SUPPLIER_TABLE_PATH}`} 
							component={props => <EBookSupplierListPage {...props} />}
							form={FORM_EBOOK_SUPPLIER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_SUPPLIER_TABLE_PATH}/:param`} 
							component={props => <EBookSupplierListPage {...props} />}
							form={FORM_EBOOK_SUPPLIER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_TERMINAL_TABLE_PATH}`} 
							component={props => <EBookTerminalListPage {...props} />}
							form={FORM_EBOOK_TERMINAL_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_TERMINAL_TABLE_PATH}/:param`} 
							component={props => <EBookTerminalListPage {...props} />}
							form={FORM_EBOOK_TERMINAL_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={ESTIMATION_TABLE_PATH} 
							component={props => <EstimationListPage {...props} />}
							form={FORM_ESTIMATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${ESTIMATION_TABLE_PATH}/:param`} 
							component={props => <EstimationListPage {...props} />}
							form={FORM_ESTIMATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={EVENT_TABLE_PATH} 
							component={props => <EventListPage {...props} />}
							form={FORM_EVENT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EVENT_TABLE_PATH}/:param`} 
							component={props => <EventListPage {...props} />}
							form={FORM_EVENT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={HONOR_TABLE_PATH} 
							component={props => <HonorListPage {...props} />} 
							form={FORM_HONOR_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${HONOR_TABLE_PATH}/:param`} 
							component={props => <HonorListPage {...props} />} 
							form={FORM_HONOR_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={LIBRARY_TABLE_PATH} 
							component={props => <LibraryListPage {...props} />} 
							form={FORM_LIBRARY_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${LIBRARY_TABLE_PATH}/:param`} 
							component={props => <LibraryListPage {...props} />} 
							form={FORM_LIBRARY_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={MESSAGE_TABLE_PATH} 
							component={props => <MessageListPage {...props} />} 
							form={FORM_MESSAGE_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${MESSAGE_TABLE_PATH}/:param`} 
							component={props => <MessageListPage {...props} />} 
							form={FORM_MESSAGE_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={OFFER_TABLE_PATH} 
							component={props => <OfferListPage {...props} />} 
							form={FORM_OFFER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${OFFER_TABLE_PATH}/:param`}
							component={props => <OfferListPage {...props} />} 
							form={FORM_OFFER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={ORDER_TABLE_PATH} 
							component={props => <OrderListPage {...props} />} 
							form={FORM_ORDER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${ORDER_TABLE_PATH}/:param`} 
							component={props => <OrderListPage {...props} />} 
							form={FORM_ORDER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={ORGANIZATION_TABLE_PATH} 
							component={props => <OrganizationListPage {...props} />} 
							form={FORM_ORGANIZATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${ORGANIZATION_TABLE_PATH}/:param`} 
							component={props => <OrganizationListPage {...props} />} 
							form={FORM_ORGANIZATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={LOCATION_TABLE_PATH} 
							component={props => <LocationListPage {...props} />} 
							form={FORM_LOCATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${LOCATION_TABLE_PATH}/:param`} 
							component={props => <LocationListPage {...props} />} 
							form={FORM_LOCATION_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={PROCUREMENT_TABLE_PATH} 
							component={props => <ProcurementListPage {...props} />} 
							form={FORM_PROCUREMENT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${PROCUREMENT_TABLE_PATH}/:param`} 
							component={props => <ProcurementListPage {...props} />} 
							form={FORM_PROCUREMENT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={PRODUCT_TABLE_PATH} 
							component={props => <ProductListPage {...props} />} 
							form={FORM_PRODUCT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${PRODUCT_TABLE_PATH}/:param`} 
							component={props => <ProductListPage {...props} />} 
							form={FORM_PRODUCT_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={PROVIDER_TABLE_PATH} 
							component={props => <ProviderListPage {...props} />} 
							form={FORM_PROVIDER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${PROVIDER_TABLE_PATH}/:param`} 
							component={props => <ProviderListPage {...props} />} 
							form={FORM_PROVIDER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={TOPIC_TABLE_PATH} 
							component={props => <TopicListPage {...props} />} 
							form={FORM_TOPIC_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${TOPIC_TABLE_PATH}/:param`} 
							component={props => <TopicListPage {...props} />} 
							form={FORM_TOPIC_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={USER_GROUP_TABLE_PATH} 
							component={props => <UserGroupListPage {...props} />} 
							form={FORM_USER_GROUP_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${USER_GROUP_TABLE_PATH}/:param`} 
							component={props => <UserGroupListPage {...props} />} 
							form={FORM_USER_GROUP_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={USER_TABLE_PATH} 
							component={props => <UserListPage {...props} />} 
							form={FORM_USER_TABLE} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${USER_TABLE_PATH}/:param`} 
							component={props => <UserListPage {...props} />} 
							form={FORM_USER_TABLE} 
							app={this} 
						/>


						<AuthorizedRoute exact path={`${AUTHORITY_AUTHOR_PATH}/:id`} 
							component={props => <AuthorityAuthorPage {...props} />} 
							form={FORM_AUTHORITY_AUTHOR_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${AUTHORITY_PUBLISHER_PATH}/:id`} 
							component={props => <AuthorityPublisherPage {...props} />} 
							form={FORM_AUTHORITY_PUBLISHER_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${BOOK_PATH}/:id`} 
							component={props => <BookPage {...props} />} 
							form={FORM_BOOK_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${BOOK_PROD_PATH}/:id`} 
							component={props => <BookProdPage {...props} />} 
							form={FORM_BOOK_PROD_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${CLASSIFIER_PATH}/:id`} 
							component={props => <ClassifierPage {...props} />} 
							form={FORM_CLASSIFIER_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${CONFIRMATION_PATH}/:id`} 
							component={props => <ConfirmationPage {...props} />} 
							form={FORM_CONFIRMATION_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${CONTACT_PATH}/:id`} 
							component={props => <ContactPage {...props} />} 
							form={FORM_CONTACT_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${DERIVATIVE_PATH}/:id`} 
							component={props => <DerivativePage {...props} />} 
							form={FORM_DERIVATIVE_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${DIGITAL_STORE_PATH}/:id`} 
							component={props => <DigitalStorePage {...props} />} 
							form={FORM_DIGITAL_STORE_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${ESTIMATION_PATH}/:id`} 
							component={props => <EstimationPage {...props} />} 
							form={FORM_ESTIMATION_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EVENT_PATH}/:id`} 
							component={props => <EventPage {...props} />} 
							form={FORM_EVENT_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EVENT_PATH}/plain/:id`} 
							component={props => <EventPlainPage {...props} />} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${HONOR_PATH}/:id`} 
							component={props => <HonorPage {...props} />} 
							form={FORM_HONOR_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${LIBRARY_PATH}/:id`} 
							component={props => <LibraryPage {...props} />} 
							form={FORM_LIBRARY_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${MESSAGE_PATH}/:id`} 
							component={props => <MessagePage {...props} />} 
							form={FORM_MESSAGE_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${OFFER_PATH}/:id`} 
							component={props => <OfferPage {...props} />} 
							form={FORM_OFFER_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${ORDER_PATH}/:id`} 
							component={props => <OrderPage {...props} />} 
							form={FORM_ORDER_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${ORGANIZATION_PATH}/:id`} 
							component={props => <OrganizationPage {...props} />} 
							form={FORM_ORGANIZATION_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${LOCATION_PATH}/:id`} 
							component={props => <LocationPage {...props} />} 
							form={FORM_LOCATION_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${PROCUREMENT_PATH}/:id`} 
							component={props => <ProcurementPage {...props} />} 
							form={FORM_PROCUREMENT_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${PRODUCT_PATH}/:id`} 
							component={props => <ProductPage {...props} />} 
							form={FORM_PRODUCT_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${PROVIDER_PATH}/:id`} 
							component={props => <ProviderPage {...props} />} 
							form={FORM_PROVIDER_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${TOPIC_PATH}/:id`} 
							component={props => <TopicPage {...props} />} 
							form={FORM_TOPIC_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${TOPIC_PATH}/:id/:param`} 
							component={props => <TopicPage {...props} />} 
							form={FORM_TOPIC_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${USER_GROUP_PATH}/:id`} 
							component={props => <UserGroupPage {...props} />} 
							form={FORM_USER_FT} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${USER_PATH}/:id`} 
							component={props => <UserPage {...props} />} 
							form={FORM_USER_GROUP_FT} 
							app={this} 
						/>

						<AuthorizedRoute exact path={`${AUTHORITY_AUTHOR_PLAIN_PATH}/:id`} 
							component={props => <AuthorityAuthorPlainPage {...props} />} 
							form={FORM_AUTHORITY_AUTHOR_PLAIN} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${AUTHORITY_PUBLISHER_PLAIN_PATH}/:id`} 
							component={props => <AuthorityPublisherPlainPage {...props} />} 
							form={FORM_AUTHORITY_PUBLISHER_PLAIN} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${BOOK_PLAIN_PATH}/:id`} 
							component={props => <BookPlainPage {...props} />} 
							form={FORM_BOOK_PLAIN} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_REPORT_PLAIN_PATH}/:id`} 
							component={props => <PlainEBookReportPage {...props} />} 
							form={FORM_EBOOK_REPORT_PLAIN} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${EBOOK_SUPPLIER_PLAIN_PATH}/:id`} 
							component={props => <PlainEBookSupplierPage {...props} />} 
							form={FORM_EBOOK_SUPPLIER_PLAIN} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${OFFER_PLAIN_PATH}/:id`} 
							component={props => <OfferPlainPage {...props} />} 
							form={FORM_OFFER_PLAIN} 
							app={this} 
						/>
						<AuthorizedRoute exact path={`${PRODUCT_PLAIN_PATH}/:id`} 
							component={props => <ProductPlainPage {...props} />} 
							form={FORM_PRODUCT_PLAIN} 
							app={this} 
						/>

						<AuthorizedRoute exact path={DBMANAGER_PATH} 
							component={DbManagerPage}
							form={FORM_DBMANAGER} 
							app={this} 
						/>
						<AuthorizedRoute exact path={SYSINFO_PATH} 
							component={SystemInfoPage}
							form={FORM_DBMANAGER} 
							app={this} 
						/>
						<AuthorizedRoute exact path={CONVERTER_PATH} component={ConverterPage} />
						<AuthorizedRoute exact path={BOOKSUPPLY_PATH} component={BookSupplyPage} />
					</Switch>
					<Footer />
				</div>
			</Router>
		);
	}
}

function AuthorizedRoute({ component: Component, ...rest }) {
	let resource = getCurrentResource().App;
	let userInfo = getUserInfo();
	let isAllowed = false;
	if( userInfo.permission.superuser ) {
		isAllowed = true;
	} else {
		if( rest.form == null ) {
			if( rest.path == HELLO_PATH || rest.path == PROFILE_PATH)
				isAllowed = true;
			else
				isAllowed = false;
		} else {
			isAllowed = canViewForm(rest.form, userInfo);
		}
	}
	return (
		<Route
			{...rest} render={props => isAllowed ? 
				<Component {...props} app={rest.app} /> :
				<BomMessagePage app={rest.app} message={resource.msgAccessDenied} /> 
			}
		/>
	);
}

export default App;
