import React from 'react';

import Label from '../../../components/plain/Label';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldFile from '../../../components/plain/FieldFile';
import FieldList_CardsPanel from '../../../components/plain/FieldList_CardsPanel';

import {getLanguage} from '../../../util/localStorage';

class TabBrHonors extends React.Component {
	
	constructor(props) {
		super(props);
	}

	honorCardBuilder = (fieldHonor, index) => {
		let card = '';
		
		if(fieldHonor.value.honorInfo.value.length > 0) {
			let currentLanguage = getLanguage();
			let info = null;
			for(let inf of fieldHonor.value.honorInfo.value) {
				if( inf.value.language.value == currentLanguage && inf.value.name.value ) {
					info = inf;
					break;
				}
			}
			if( info == null ) {
				for(let inf of fieldHonor.value.honorInfo.value) {
					if( inf.value.name.value ) {
						info = inf;
						break;
					}
				}
			}			
			if( info )
				card = info.value.name.value
		}
		
		if( ! card ) {
			for(let f of fieldHonor.value.images.value) {
				if( f.value.resourceName.value ) {
					card = f.value.resourceName.value;
					break;
				}
			}
		}
		
		return card;
	}
	
	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{padding: '10px' }}>
				<FieldList_CardsPanel path={'/honors'} instance={instance} plain={plain}
					labelEmptyToHeader={true} ignoreTitle={true} 
					itemComponent={HonorPanel} itemCardBuilder={this.honorCardBuilder}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '170px',
							maxWidth: '170px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGray',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		);
	}
}

class HonorPanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}

	honorInfoCardBuilder = (fieldHonorInfo, index) => {
		let card = '';

		if( fieldHonorInfo.value.name.value )
			card += fieldHonorInfo.value.name.value;
			
		if( card.length > 40 )
			card = card.substring(0, 37) + '...'
			
		return card;
	}
	fileCardBuilder = (fieldFile, index) => {
		let card = '';

		if( fieldFile.value.resourceName.value )
			card += fieldFile.value.resourceName.value;
			
		if( card.length > 40 )
			card = card.substring(0, 37) + '...'
			
		return card;
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'rgb(220,220,220)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'type'} parentField={fieldGroup} plain={plain}
							styleSelect={{}}
						/>
					</div>
					<FieldList_CardsPanel path={'/honorInfo'} instance={instance} parentField={fieldGroup} plain={plain}
						labelEmptyToHeader={true} ignoreTitle={true}
						itemComponent={HonorInfoPanel} itemCardBuilder={this.honorInfoCardBuilder}
						styles={{
							divMainStyle: {
								marginTop: '10px',
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue'
							},
							divCardsBlock: {
								marginRight: '10px',
								minWidth: '170px',
								maxWidth: '170px',
								backgroundColor: 'rgb(200,200,200)',
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
								
							}
						}}
					/>
					<FieldList_CardsPanel path={'/images'} instance={instance} parentField={fieldGroup} plain={plain}
						labelEmptyToHeader={true} 
						itemComponent={FilePanel} itemCardBuilder={this.fileCardBuilder}
						styles={{
							divMainStyle: {
								marginTop: '10px',
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue'
							},
							divCardsBlock: {
								marginRight: '10px',
								minWidth: '200px',
								backgroundColor: 'rgb(200,200,200)',
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
							}
						}}
					/>
				</div>
			</div>
		)
	}
}

class HonorInfoPanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'name'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'description'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

class FilePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'resourceName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<Label title={'Файл'} style={{marginRight: '10px'}}/>
						<FieldFile instance={instance} path={'resourceFile'} parentField={fieldGroup} plain={plain}
							styleDiv={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrHonors;