export const CLASS_AUTHORITY_AUTHOR = 'AuthorityAuthor';
export const CLASS_AUTHORITY_PUBLISHER = 'AuthorityPublisher';
export const CLASS_BOOK = 'Book';
export const CLASS_CLASSIFIER = 'Classifier';
export const CLASS_CONFIRMATION = 'Confirmation';
export const CLASS_CONTACT = 'Contact';
export const CLASS_DERIVATIVE = 'Derivative';
export const CLASS_DIGITAL_STORE = 'DigitalStore';
export const CLASS_EBOOK_PAYMENT = 'EBookPayment';
export const CLASS_EBOOK_REPORT = 'EBookReport';
export const CLASS_EBOOK_REPORT_ITEM = 'EBookReportItem';
export const CLASS_EBOOK_SUPPLIER = 'EBookSupplier';
export const CLASS_EBOOK_TERMINAL = 'EBookTerminal';
export const CLASS_ESTIMATION = 'Estimation';
export const CLASS_EVENT = 'Event';
export const CLASS_HONOR = 'Honor';
export const CLASS_LIBRARY = 'Library';
export const CLASS_LOCATION = 'Location';
export const CLASS_MESSAGE = 'Message';
export const CLASS_OFFER = 'Offer';
export const CLASS_ORDER = 'Order';
export const CLASS_ORGANIZATION = 'Organization';
export const CLASS_PLACE = 'Place';
export const CLASS_PROCUREMENT = 'Procurement';
export const CLASS_PRODUCT = 'Product';
export const CLASS_PROVIDER = 'Provider';
export const CLASS_TOPIC = 'Topic';
export const CLASS_USER = 'User';
export const CLASS_USER_GROUP = 'UserGroup';
