import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_Enum from '../../../components/plain/FieldList_Enum';
import FieldList_MultilangString from '../../../components/plain/FieldList_MultilangString';

class TabBrPhysChar extends React.Component {
	
	parentField = null;
	
	constructor(props) {
		super(props);
		this.parentField = props.parentField;
		if( ! this.parentField ) {
			this.parentField = props.instance.rootField;
		}
		let productForm = '';
		if( props.instance.model.entityId == 'Book' ) {
			productForm = props.instance.rootField.value.edition.value.productForm.value;
		} else {
			productForm = props.instance.rootField.value.bookProduct.value.edition.value.productForm.value;
		}
		this.state = {
			productForm: productForm,
			enumProductFormDetail: this.createEnumProductFormDetail() 
		}
	}
	createEnumProductFormDetail = () => {
		let	enumeration = {enumerationId: '', items: [{}]} 
		if( ! this.props.instance )
			return enumeration;
		let productForm = '';
		if( this.props.instance.model.entityId == 'Book' ) {
			productForm = this.props.instance.rootField.value.edition.value.productForm.value;
		} else {
			productForm = this.props.instance.rootField.value.bookProduct.value.edition.value.productForm.value;
		}
		if( productForm ) {
			let firstChar = productForm.substring(0, 1); 
			for( let item of this.props.plain.enumProductFormDetail.items ) {
				if( item.value.startsWith(firstChar) )
					enumeration.items.push( item );
			} 
		}
		return enumeration;
		
	}
	componentDidUpdate(prevProps, prevState) {
		if( prevProps.instance !== this.props.instance ) {
			if( prevProps.instance )
				prevProps.instance.removeChangeListener( this );
			if( this.props.instance ) {
				this.props.instance.addChangeListener( this );
				this.setState({productForm: this.props.instance.rootField.value.edition.value.productForm.value})
			}
		}
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}

	handleInstanceChange = async (event) => {
		let field = event.field;
		if( field && field.model.fieldId == 'productForm' ) {
			this.setState({productForm: field.value, enumProductFormDetail: this.createEnumProductFormDetail()});
		}
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.parentField;

		let defaultDiv =  (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
					<FieldString instance={instance} path={'physChar/volume'} parentField={parentField} plain={plain}
						styleInput={{width: '200px', marginRight: '20px'}}
					 />
					<FieldEnum instance={instance} path={'physChar/unit'} parentField={parentField} plain={plain}
						styleSelect={{}}
					/>
				</div>
			</div>
		)

		let form = this.state.productForm;
		if( ! form )
			return defaultDiv;
			
		if( form.startsWith('A') ) {
			return (
				<div style={{padding: '20px 20px 20px 20px' }}>
					<div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
						<FieldEnum instance={instance} path={'physChar/media'} parentField={parentField} plain={plain}
							styleSelect={{flexGrow: '0', marginRight: '20px'}}
						/>
						<FieldList_Enum
							instance={instance} path={'physChar/productFormDetail'} parentField={parentField} plain={plain}
							labelEmptyToHeader={true} enumeration={this.state.enumProductFormDetail}
							table={{width: '100%', styles: {}}}
							styles={{divMain: {flexGrow: '0', marginRight: '10px'}}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
						<FieldString instance={instance} path={'physChar/volume'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldEnum instance={instance} path={'physChar/unit'} parentField={parentField} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldString key='A' instance={instance} path={'physChar/fileSize'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1'}}
						 />
					</div>
				</div>
			)
		}
		if( form.startsWith('B') ) {
			return (
				<div style={{padding: '20px 20px 20px 20px' }}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FieldEnum instance={instance} path={'physChar/media'} parentField={parentField} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldEnum instance={instance} path={'physChar/printType'} parentField={parentField} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldString instance={instance} path={'physChar/mediaFormat'} parentField={parentField} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
						<FieldString instance={instance} path={'physChar/volume'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldEnum instance={instance} path={'physChar/unit'} parentField={parentField} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldString instance={instance} path={'physChar/pubSheets'} parentField={parentField} plain={plain}
							styleInput={{width: '60px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'physChar/printSheets'} parentField={parentField} plain={plain}
							styleInput={{width: '60px', flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
						<FieldString instance={instance} path={'physChar/height'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'physChar/width'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'physChar/thickness'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'physChar/weight'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', marginTop: '10px'}}>
						<FieldList_Enum
							instance={instance} path={'physChar/productFormDetail'} parentField={parentField} plain={plain}
							labelEmptyToHeader={true} enumeration={this.state.enumProductFormDetail}
							table={{width: '100%', styles: {}}}
							styles={{divMain: {flexGrow: '0', marginRight: '10px'}}}
						/>
						<FieldList_MultilangString
							instance={instance} path={'physChar/other'} parentField={parentField} plain={plain}
							labelEmptyToHeader={true}
							table={{width: '100%', styles: {}}}
							styles={{divMain: {flexGrow: '1'}}}
						/>
					</div>
					<FieldList_MultilangString
						instance={instance} path={'physChar/appendix'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true}
						table={{width: '100%', styles: {}}}
						styles={{divMain: {flexGrow: '1', marginTop: '10px'}}}
					/>
					<FieldList_MultilangString
						instance={instance} path={'physChar/comment'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true} enumeration={this.state.enumProductFormDetail}
						table={{width: '100%', styles: {}}}
						styles={{divMain: {flexGrow: '1', marginTop: '10px'}}}
					/>
				</div>
			)
		}
		if( form.startsWith('E') ) {
			return (
				<div style={{padding: '20px 20px 20px 20px' }}>
					{/*}
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FieldList_Strings title={'Технічний захист'}
							instance={instance} path={'/edition/epubTechnicalProtect'} plain={plain}
							labelEmptyToHeader={true}
							styles={{divMain: {flexGrow: '1'}}}
						/>
					</div>
					*/}
					<div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
						<FieldEnum instance={instance} path={'physChar/media'} parentField={parentField} plain={plain}
							styleSelect={{flexGrow: '0', marginRight: '20px'}}
						/>
						<FieldList_Enum
							instance={instance} path={'physChar/productFormDetail'} parentField={parentField} plain={plain}
							labelEmptyToHeader={true} enumeration={this.state.enumProductFormDetail}
							table={{width: '100%', styles: {}}}
							styles={{divMain: {flexGrow: '0'}}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
						<FieldString instance={instance} path={'physChar/volume'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldEnum instance={instance} path={'physChar/unit'} parentField={parentField} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldString key='A' instance={instance} path={'physChar/fileSize'} parentField={parentField} plain={plain}
							styleInput={{width: '100px', flexGrow: '1'}}
						 />
					</div>
				</div>
			)
		}
		
		return defaultDiv;
	}
}

export default TabBrPhysChar; 