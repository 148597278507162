import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldFile from '../../../components/plain/FieldFile';
import FieldList_CardsPanel from '../../../components/plain/FieldList_CardsPanel';
import DivFileChar from './DivFileChar';

class TabBrFiles extends React.Component {
	
	constructor(props) {
		super(props);
	}

	fileCardBuilder = (fieldFileLink, index) => {
		let card = '';
		
		let typeText = fieldFileLink.value.resourceType.value;
		if( typeText )
			card += '[' + typeText + ']; ';
		
		if( fieldFileLink.value.resourceName.value )
			card += fieldFileLink.value.resourceName.value;
			
		if( card.length > 40 )
			card = card.substring(0, 37) + '...'
		return card;
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '10px' }}>
				<FieldList_CardsPanel path={'fileLink'} instance={instance} parentField={parentField} plain={plain}
					labelEmptyToHeader={true}  ignoreTitle={true}
					itemComponent={FilePanel} itemCardBuilder={this.fileCardBuilder}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '300px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGray',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		);
	}
}

class FilePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'resourceType'} parentField={fieldGroup} plain={plain}
							styleInput={{width: '150px', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'resourceName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'resourceURI'} parentField={fieldGroup} plain={plain}
							styles={{ divMain: {flexGrow: '1'}}}
						 />
					</div>
					<DivFileChar instance={instance} fieldGroup={fieldGroup} plain={plain}
						styles={{ divMain: {marginBottom: '15px'} }} 
					/>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldFile instance={instance} path={'resourceFile'} parentField={fieldGroup} plain={plain}
							styleDiv={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrFiles;