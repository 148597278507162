import React from 'react';

import Label from './Label';
import FieldList from './FieldList';
import FieldString from './FieldString';
import FieldEnum from './FieldEnum';
import FieldDate from './FieldDate';
import FieldInteger from './FieldInteger';
import FieldDecimal from './FieldDecimal';
import FieldDatetime from './FieldDatetime';
import FieldReference from './FieldReference';

import { getDefinedValue, getDefinedItem } from '../../util/tools';
import {
	DATATYPE_STRING,
//	DATATYPE_MULTILANG_STRING,
	DATATYPE_ENUM,
	DATATYPE_INTEGER,
	DATATYPE_DECIMAL,
	DATATYPE_DATETIME,
	DATATYPE_REFERENCE
} from '../../util/realm/instance';

class FieldList_TableGroup extends FieldList {

	/*
		createHeaderCellComponent
		createDataCellComponent
		table
			columns [{
				columnId
				refNodes
				title
				tooltip
				styles
					divMainLabelHeader
					thead
					trHeader
					tdHeader
					tdData
					dataComponent
			}]
			border
		styles
			divMain
	*/
	constructor(props) {
		super(props);
		this.init();
	}
	
	render() {
		if( ! this.field ) {
			return this.createDivUnknownField();			
		}
		console.log('TableGroup render', this.props.instance, this.field, this.props.path);
		let styles = getDefinedValue( this.props.styles, {} );
		if( this.field.instance != this.props.instance ) {
			console.log('TableGroup temporary render', this.props.instance, this.field, this.props.path);
			let divMainStyle = getDefinedValue(styles.divMain, {});
			return (
				<div style={divMainStyle}>
					...
				</div>
			);
		}

		let headerHtml = this.createHeaderHtml(); // Label, buttons
		let tableHtml = null;

		if( ! this.props.labelEmptyToHeader || this.field.value.length > 0 ) {
			let headerCellsHtml = this.props.table.columns.map((col) => {
				let component = null;
				if( this.props.createHeaderCellComponent ) {
					component = this.props.createHeaderCellComponent(col.columnId);
				}
				if( component == null ) {
					let itemModel = this.props.instance.getSubfieldModel(this.field.model.item, col.columnId);
					let title = col.title;
					if( ! title ) {
						if( itemModel == null )
							title = col.columnId;
						else {
							title = itemModel.shortTitle;
							if( ! title )
								title = itemModel.title;
						}
					}
					let tooltip = col.tooltip;
					if( ! tooltip && itemModel != null )
						tooltip = itemModel.description;
						
					let labelStyle = Object.assign( 
						{color: 'black', fontWeight: 'bold', fontStyle: 'normal'}, 
						getDefinedItem(col, ['styles', 'divMainLabelHeader'], {}) ); 
					component = <Label title={title} tooltip={tooltip} styles={{ divMain: labelStyle}} />
				}
				
				let tdHeaderStyle = Object.assign(
					{textAlign: 'center'},
					getDefinedItem(col, ['styles', 'tdHeader'], {}) 
				); 
				return (
					<td key={col.columnId} style={tdHeaderStyle}>
						{component}
					</td>
				)
			});

			let rowsHtml = this.field.value.map((itemField, rowIndex) => {
				let trStyle = {backgroundColor: 'lightGray'};
				if( rowIndex == this.state.selectedIndex )
					trStyle.backgroundColor = 'lightBlue';
				let cellsHtml = this.props.table.columns.map((col, index) => {
					let instance = this.props.instance;
					let plain = this.props.plain;
					let colStyles = getDefinedValue(col.styles, {});
					
					let component = null;
					if( this.props.createDataCellComponent ) {
						component = this.props.createDataCellComponent(
							col.columnId, rowIndex, instance, itemField, plain, 
							this.props.plainDirtyCounter
						);
					}
					if( component == null ) {
						let itemModel = this.props.instance.getSubfieldModel(this.field.model.item, col.columnId);
						if( itemModel == null ) {
							return <div style={{backgroundColor: 'lightYellow'}}>Unknown item: {col.columnId}</div>
						}
						
						if( itemModel.dataType == DATATYPE_STRING ) {
							let componentStyles = Object.assign(
								{ input: {width: '95%'}},
								getDefinedValue(colStyles.dataComponent, {})
							);
							if( itemModel.isDate )
								component = (
									<FieldDate instance={instance} path={col.columnId} plain={plain} readOnly={this.props.readOnly}
										parentField={itemField} ignoreTitle={true} styles={componentStyles} 
									/>
								);
							else
								component = (
									<FieldString instance={instance} path={col.columnId} plain={plain} readOnly={this.props.readOnly} 
										parentField={itemField} ignoreTitle={true} styles={componentStyles} 
									/>
								);
						}
						if( itemModel.dataType == DATATYPE_ENUM ) {
							let componentStyles = Object.assign(
								{ select: {width: '95%'}},
								getDefinedValue(colStyles.dataComponent, {})
							);
							component = (
								<FieldEnum instance={instance} path={col.columnId} plain={plain} readOnly={this.props.readOnly} 
									parentField={itemField} ignoreTitle={true} styles={componentStyles} 
								/>
							);
						}
						if( itemModel.dataType == DATATYPE_INTEGER ) {
							let componentStyles = Object.assign(
								{ input: {width: '95%'}},
								getDefinedValue(colStyles.dataComponent, {})
							);
							component = (
								<FieldInteger instance={instance} path={col.columnId} plain={plain} readOnly={this.props.readOnly} 
									parentField={itemField} ignoreTitle={true} styles={componentStyles} 
								/>
							);
						}
						if( itemModel.dataType == DATATYPE_DECIMAL ) {
							let componentStyles = Object.assign(
								{ input: {width: '95%'}},
								getDefinedValue(colStyles.dataComponent, {})
							);
							component = (
								<FieldDecimal instance={instance} path={col.columnId} plain={plain} readOnly={this.props.readOnly} 
									parentField={itemField} ignoreTitle={true} styles={componentStyles} 
								/>
							);
						}
						if( itemModel.dataType == DATATYPE_DATETIME ) {
							let componentStyles = Object.assign(
								{ input: {width: '95%'}},
								getDefinedValue(colStyles.dataComponent, {})
							);
							component = (
								<FieldDatetime instance={instance} path={col.columnId} plain={plain} readOnly={this.props.readOnly} 
									parentField={itemField} ignoreTitle={true} styles={componentStyles} 
								/>
							);
						}
						if( itemModel.dataType == DATATYPE_REFERENCE ) {
							let componentStyles = Object.assign(
								{ divMain: {width: '95%'}},
								getDefinedValue(colStyles.dataComponent, {})
							);
							component = (
								<FieldReference instance={instance} path={col.columnId} plain={plain} readOnly={this.props.readOnly} 
									parentField={itemField} refNodes={col.refNodes} 
									ignoreTitle={true} styles={componentStyles} 
								/>
							);
						}
						/*
						if( itemModel.dataType == DATATYPE_MULTILANG_STRING ) {
							let componentStyles = Object.assign(
								{ divMain: {padding: '5px'}},
								getDefinedValue(colStyles.dataComponent, {})
							);
							component = (
								<FieldList_MultilangString
									instance={instance} path={col.columnId} plain={plain}
									parentField={itemField} ignoreTitle={true} labelEmptyToHeader={true} 
									styles={componentStyles}
									headerPosition={HEADER_POSITION_RIGHT}
								/>
							);
						}
						*/
					}

					
					let tdCellStyle = Object.assign(
						{},
						getDefinedItem(col, ['styles', 'tdData'], {}) 
					); 
					return (
						<td key={col.columnId} style={tdCellStyle}>
							{component}
						</td>
					)
				});
				return (
					<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(rowIndex)} }>
						{cellsHtml}
					</tr>
				)
			});

			let border = getDefinedValue(this.props.table.border, '1');
			tableHtml = (
				<table border={border} style={getDefinedValue(styles.table, {})}>
					<thead style={getDefinedValue(styles.thead, {})}>
						<tr style={getDefinedValue(styles.trHeader, {})}>
							{headerCellsHtml}
						</tr>
					</thead>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			);
		}

		let divMainStyle = getDefinedValue(styles.divMain, {});
		return (
			<div style={divMainStyle}>
				{headerHtml}
				{tableHtml}
			</div>
		);
	}
	
}

export default FieldList_TableGroup;