import React, { Component } from 'react';
import InfoMessageModal from './InfoMessageModal';
import { getCurrentResource } from '../../util/tools';

/*
	USAGE:
	
	constructor(props) {
		super(props);
		this.refMessage = React.createRef();
		...
	}
	
	message = (msg) => {
		this.refMessage.current.showMessage(msg);
	}	
	
	render() {
		return (
			<React.Fragment>
				...
				<InfoMessage ref={this.refMessage} />
			</React.Fragment>
		)
	}
	
*/

class InfoMessage extends Component {

	/*
		props
			doAfterClose 
	*/
	constructor(props) {
		super(props);
		this.doAfterClose = props.doAfterClose;
		this.resource = getCurrentResource().components.message.InfoMessage;
		this.state = {
			isModalMessageOpen: false,
			message: {
				title: '',
				mainMessage: '',
				additionalMessage: '',
				maxWidth: ''
			}
		}
	}

	showResponse = (response, defaultMessage, doAfterClose) => {
		if( defaultMessage == null )
			defaultMessage = '';
		var msg = {
			title: response.title,
			mainMessage: response.message,
			additionalMessage: response.log,
		}
		if( ! msg.mainMessage ) {
			if( response.success )
				msg.mainMessage = defaultMessage;
			else
				msg.mainMessage = this.resource.defaultNotSuccessMessage;
		} else if( msg.mainMessage.length > 60 ) {
			msg.additionalMessage = msg.mainMessage + '\n\n' + msg.additionalMessage;
			msg.mainMessage = msg.mainMessage.substring(0, 57) + '...';  
		}
		this.showMessage( msg, doAfterClose );
	}
	showMessage = (message, doAfterClose) => {
		this.doAfterClose = doAfterClose;
		this.setState({ message: message, isModalMessageOpen: true });	
	}
	closeModalMessage = () => {
		if( this.doAfterClose != null ) {
			this.setState({ isModalMessageOpen: false }, this.doAfterClose);
		} else {	
			this.setState({ isModalMessageOpen: false });
		}
	}

	render() {
		return (
			<InfoMessageModal 
				isModalOpen={this.state.isModalMessageOpen} 
				close={this.closeModalMessage} 
				message={this.state.message} 
			/>			
		)
	}
	
}

export default InfoMessage;
