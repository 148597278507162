import config from '../config/config';
import { getLanguage, getUserInfo } from './localStorage';
import { getCurrentResource } from './tools';
import { 
	ACTION_CLASSIFIER_IMPORT,
	ACTION_PROVIDER_IMPORT,
	ACTION_USH_EXPORT,
	ACTION_USH_IMPORT,

	ACTION_BOOK_EXPORT,
	ACTION_BOOK_IMPORT,
	ACTION_EBOOK_BANK_IMPORT,
	ACTION_EBOOK_PAYMENT_IMPORT,
	ACTION_EBOOK_PAYMENT_DOWNLOAD,
	ACTION_EBOOK_REPORT_EXPORT,
	ACTION_EBOOK_REPORT_IMPORT,
	ACTION_EBOOK_SUPPLIER_EXPORT,
	ACTION_EBOOK_SUPPLIER_UPLOAD,
	ACTION_ONIX_EXPORT,
	ACTION_ONIX_IMPORT,
	ACTION_ONIX_IMPORT_LOCAL,
	ACTION_ONIX_IMPORT_ELIBRI,
	ACTION_ORDER_CREATE_BY_OFFER,
	ACTION_ORDERS_CREATE_BY_OFFERS,
	ACTION_PROCUREMENT_CREATE_BY_OFFER,
	ACTION_PRODUCT_BORROW_BOOK,
	ACTION_ELIBRI_API
} from './consts/actions';

export const LOGIN_API_URL = 'security/userinfo';
export const GETUSERINFO_API_URL = 'security/getuserinfo';
export const USER_PROFILE_API_URL = 'security/userprofile';
export const CHANGE_PASSWORD_API_URL = 'security/changepassword';

export const REALM_GET_HASH_API_URL = 'realm/gethash';
export const REALM_GET_REALM_API_URL = 'realm/getrealm';

export const DBMANAGER_API_URL = 'dbmanager/update';
export const DBMANAGER_GET_DB_ACCESS_API_URL = 'dbmanager/getdbaccess';
export const DBMANAGER_SET_DB_ACCESS_API_URL = 'dbmanager/setdbaccess';
export const SYSINFO_GET_API_URL = 'sysinfo/get';

export const QUERY_GETFORM_API_URL = 'query/getform'; 
export const QUERY_GETPAGE_API_URL = 'query/getpage'; 
export const QUERY_GETCLASSIFIERS_API_URL = 'query/getclassifiers'; 
export const QUERY_LOADGROUPSANDUSERS_API_URL = 'query/loadgroupsandusers'; 

export const TREE_POPULATEROOTS_API_URL = 'tree/populateroots'; 
export const TREE_POPULATENODE_API_URL = 'tree/populatenode'; 
export const TREE_POPULATEBUNDLE_API_URL = 'tree/populatebundle'; 

export const MASTER_LOAD_REF_API_URL = 'master/loadref';
export const MASTER_LOAD_REF_LIST_API_URL = 'master/loadreflist';
export const MASTER_LOAD_OBJECT_API_URL = 'master/loadobject';
export const MASTER_LOAD_LIST_API_URL = 'master/loadlist';

export const ENTITY_LOAD_BODY_API_URL = 'entity/load_body';
export const ENTITY_LOAD_LIST_API_URL = 'entity/load_list';
export const ENTITY_LOAD_API_URL = 'entity/load';
export const ENTITY_LOAD_PLAIN_API_URL = 'entity/loadplain';
export const ENTITY_SAVE_API_URL = 'entity/save';
export const ENTITY_CHANGE_OWNER_API_URL = 'entity/change_owner';
export const ENTITY_MERGE_API_URL = 'entity/merge';
export const ENTITY_UPDATE_FIELD_API_URL = 'entity/update_field';
export const ENTITY_UPDATE_GLOBAL_ID_API_URL = 'entity/update_global_id';
export const ENTITY_GENERATE_GLOBAL_ID_API_URL = 'entity/generate_global_id';
export const ENTITY_DELETE_API_URL = 'entity/delete';

export const CACHE_GET_STRING_API_URL = 'cache/getstring';
export const CACHE_PUT_STRING_API_URL = 'cache/putstring';

export const PLAIN_INIT_API_URL = 'plain/init'; 

export const ACTION_AUTHORITY_AUTHOR_EXPORT_API_URL = 'action/aa/export';
export const ACTION_AUTHORITY_PUBLISHER_EXPORT_API_URL = 'action/ap/export';
export const ACTION_BOOK_EXPORT_API_URL = 'action/book/export';
export const ACTION_BOOK_IMPORT_API_URL = 'action/book/import';
export const ACTION_CLASSIFIER_IMPORT_API_URL = 'action/' + ACTION_CLASSIFIER_IMPORT;
export const ACTION_USH_EXPORT_API_URL = 'action/' + ACTION_USH_EXPORT;
export const ACTION_USH_IMPORT_API_URL = 'action/' + ACTION_USH_IMPORT;
export const ACTION_BOOKEXPORT_API_URL = 'action/' + ACTION_BOOK_EXPORT; 
export const ACTION_BOOKIMPORT_API_URL = 'action/' + ACTION_BOOK_IMPORT; 
export const ACTION_EBOOK_BANK_IMPORT_API_URL = 'action/' + ACTION_EBOOK_BANK_IMPORT;
export const ACTION_EBOOK_PAYMENT_IMPORT_API_URL = 'action/' + ACTION_EBOOK_PAYMENT_IMPORT;
export const ACTION_EBOOK_PAYMENT_DOWNLOAD_API_URL = 'action/' + ACTION_EBOOK_PAYMENT_DOWNLOAD;
export const ACTION_EBOOK_REPORT_GET_SUPPLIER_API_URL = 'action/ebook/report/getsupplier';
export const ACTION_EBOOK_REPORT_EXPORT_API_URL = 'action/' + ACTION_EBOOK_REPORT_EXPORT;
export const ACTION_EBOOK_REPORT_IMPORT_API_URL = 'action/' + ACTION_EBOOK_REPORT_IMPORT;
export const ACTION_EBOOK_SUPPLIER_EXPORT_API_URL = 'action/' + ACTION_EBOOK_SUPPLIER_EXPORT;
export const ACTION_EBOOK_SUPPLIER_UPLOAD_API_URL = 'action/' + ACTION_EBOOK_SUPPLIER_UPLOAD;
export const ACTION_CLASSIFIERIMPORT_API_URL = 'action/' + ACTION_CLASSIFIER_IMPORT; 
export const ACTION_ONIXEXPORT_API_URL = 'action/' + ACTION_ONIX_EXPORT; 
export const ACTION_ONIXIMPORT_API_URL = 'action/' + ACTION_ONIX_IMPORT; 
export const ACTION_ONIXIMPORT_LOCAL_API_URL = 'action/' + ACTION_ONIX_IMPORT_LOCAL; 
export const ACTION_ONIXIMPORT_ELIBRI_API_URL = 'action/' + ACTION_ONIX_IMPORT_ELIBRI; 
export const ACTION_ORDER_CREATEBYOFFER_API_URL = 'action/' + ACTION_ORDER_CREATE_BY_OFFER; 
export const ACTION_ORDERS_CREATEBYOFFERS_API_URL = 'action/' + ACTION_ORDERS_CREATE_BY_OFFERS; 
export const ACTION_PROCUREMENT_CREATEBYOFFER_API_URL = 'action/' + ACTION_PROCUREMENT_CREATE_BY_OFFER; 
export const ACTION_PRODUCT_EXPORT_API_URL = 'action/product/export';
export const ACTION_PRODUCT_BORROW_BOOK_API_URL = 'action/product/' + ACTION_PRODUCT_BORROW_BOOK;
export const ACTION_PROVIDER_IMPORT_API_URL = 'action/' + ACTION_PROVIDER_IMPORT; 
export const ACTION_ELIBRI_API_URL = 'action/' + ACTION_ELIBRI_API;

export const SAVE_TEMP_IMAGE_API_URL = 'image/savetemporary';

export const CONVERTER_API_URL = 'converter/knr';

export const getRequestProperties = (url) => {
	var authorization = '';
	var userInfo = getUserInfo();
	if( userInfo.jwt ) {
		authorization = 'Bearer ' + userInfo.jwt;
	}

	var realUrl = config.apiGateway.URL;
	if( config.apiGateway.BASE_PATH )
		realUrl += '/' + config.apiGateway.BASE_PATH;
	realUrl += '/' + url;

	var properties = {
		authorization: authorization,
		language: getLanguage(),
		url: realUrl
	}
	
	return properties;
}

export const performApiRequest = async (url, requestBody) => {
	url = getApiUrl(url);
	const request = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json', 
			'Accept-Language': getLanguage() 
		},
		body: JSON.stringify( requestBody )
	};
	var userInfo = getUserInfo();
	if( userInfo.jwt ) {
		request.headers.Authorization = 'Bearer ' + userInfo.jwt;
	}
	console.log( "performApiRequest", url, request );
	try{
		var response = await fetch(url, request);
	} catch(err) {
		console.log("Error", err);
		var res = getCurrentResource();
		response = {success: false, message: res.util.restapi.msgNetworkError}
		return response;
	}	
	response = await response.json();
	console.log( response );
	return response;
}

export const performApiRequestForm = async (url, form) => {
	url = getApiUrl(url);
	const request = {
		method: 'POST',
		headers: { 'Accept-Language': getLanguage() },
		body: form,
	};
	var userInfo = getUserInfo();
	if( userInfo.jwt ) {
		request.headers.Authorization = 'Bearer ' + userInfo.jwt;
	}
	console.log( request );
	var response = await fetch(url, request);
	response = await response.json();
	console.log( response );	
	return response;
}

export const performApiDownloadRequest = async (url, requestBody, blobType) => {
	url = getApiUrl(url);
	const request = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json', 
			'Accept-Language': getLanguage() 
		},
		body: JSON.stringify( requestBody )
	};
	var userInfo = getUserInfo();
	if( userInfo.jwt ) {
		request.headers.Authorization = 'Bearer ' + userInfo.jwt;
	}
	console.log( "performApiRequest", url, request );
	try{
		var response = await fetch(url, request);
		if( response.ok ) {
			var blob = await response.blob();
			blob = new Blob([blob], { type: blobType })
			response = {success: true, blob: blob};
		} else {
			response = {
				success: false, 
				message: response.headers.get('message'),
				log: response.headers.get('log')
			};
		}
		console.log( "Response", url, response );
		return response;
	} catch(err) {
		console.log("Error", err);
		var res = getCurrentResource();
		response = {success: false, message: res.util.restapi.msgNetworkError}
		return response;
	}
}


export const getApiUrl = ( url ) => {
	var fullUrl = config.apiGateway.URL;
	if( config.apiGateway.BASE_PATH )
		fullUrl += '/' + config.apiGateway.BASE_PATH;
	fullUrl += '/' + url;
	return fullUrl;
}

// -------------------------------------------------------------------------------------------------
export const performExportRequest = async (exportOptions, urlExportAction) => {

	var url = config.apiGateway.URL;
	if( config.apiGateway.BASE_PATH )
		url += '/' + config.apiGateway.BASE_PATH;
	url += '/' + urlExportAction;

	const request = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json', 
			'Accept-Language': getLanguage() 
		},
		body: JSON.stringify( exportOptions )
	};
	var userInfo = getUserInfo();
	if( userInfo.jwt ) {
		request.headers.Authorization = 'Bearer ' + userInfo.jwt;
	}
	console.log( request );
	var response = await fetch(url, request);
	if( ! response.ok ) {
		console.log( response );		
		if( response.status == 500) {
			var jsonBody = await response.json();
			response = {ok: false, message: jsonBody.message};
		} else {
			response = {ok: false, message: "HTTP error"};
		}
	}	
	
	return response;
}

export const performImportRequest = async (url, form) => {
	url = getApiUrl(url);
	const request = {
		method: 'POST',
		body: form,
		headers: { 'Accept-Language': getLanguage() }
	};
	var userInfo = getUserInfo();
	if( userInfo.jwt ) {
		request.headers.Authorization = 'Bearer ' + userInfo.jwt;
	}
	console.log( request );
	var response = await fetch(url, request);
	if( response.ok ) {
		response = await response.json();
	} else {
		response = {success: false, message: 'HTTP error ' + response.status}
	}	
	return response;
}


export const performApiGetRequest = async (url) => {
	url = getApiUrl(url);
	return performApiGetRequestFinalUrl( url );
}

export const performApiGetRequestFinalUrl = async (url) => {
	const request = {
		method: 'GET',
		headers: { 
			'Content-Type': 'application/json', 
			'Accept-Language': getLanguage() 
		}
	};
	var userInfo = getUserInfo();
	if( userInfo.jwt ) {
		request.headers.Authorization = 'Bearer ' + userInfo.jwt;
	}
	var response = await fetch(url, request);
	response = await response.json();
	return response;
}


