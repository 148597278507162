export const FORM_AUTHORITY_AUTHOR_TABLE = 'authority_author_table';
export const FORM_AUTHORITY_PUBLISHER_TABLE = 'authority_publisher_table';
export const FORM_BOOK_TABLE = 'book_table';
export const FORM_CLASSIFIER_TABLE = 'classifier_table';
export const FORM_CONFIRMATION_TABLE = 'confirmation_table';
export const FORM_CONTACT_TABLE = 'contact_table';
export const FORM_DERIVATIVE_TABLE = 'derivative_table';
export const FORM_DIGITAL_STORE_TABLE = 'digital_store_table';
export const FORM_EBOOK_BANK_TABLE = 'ebook_bank_table';
export const FORM_EBOOK_PAYMENT_TABLE = 'ebook_payment_table';
export const FORM_EBOOK_REPORT_TABLE = 'ebook_report_table';
export const FORM_EBOOK_REPORT_ITEM_TABLE = 'ebook_report_item_table';
export const FORM_EBOOK_SUPPLIER_TABLE = 'ebook_supplier_table';
export const FORM_EBOOK_TERMINAL_TABLE = 'ebook_terminal_table';
export const FORM_ESTIMATION_TABLE = 'estimation_table';
export const FORM_EVENT_TABLE = 'event_table';
export const FORM_HONOR_TABLE = 'honor_table';
export const FORM_LIBRARY_TABLE = 'library_table';
export const FORM_LOCATION_TABLE = 'location_table';
export const FORM_MESSAGE_TABLE = 'message_table';
export const FORM_OFFER_TABLE = 'offer_table';
export const FORM_ORDER_TABLE = 'order_table';
export const FORM_ORGANIZATION_TABLE = 'organization_table';
export const FORM_PROCUREMENT_TABLE = 'procurement_table';
export const FORM_PRODUCT_TABLE = 'product_table';
export const FORM_PROVIDER_TABLE = 'provider_table';
export const FORM_TOPIC_TABLE = 'topic_table';
export const FORM_USER_GROUP_TABLE = 'user_group_table';
export const FORM_USER_TABLE = 'user_table';

export const FORM_AUTHORITY_AUTHOR_FT = "authority_author_ft";
export const FORM_AUTHORITY_PUBLISHER_FT = "authority_publisher_ft";
export const FORM_BOOK_FT = "book_ft";
export const FORM_BOOK_PROD_FT = "book_prod_ft";
export const FORM_CLASSIFIER_FT = "classifier_ft";
export const FORM_CONFIRMATION_FT = "confirmation_ft";
export const FORM_CONTACT_FT = "contact_ft";
export const FORM_DERIVATIVE_FT = "derivative_ft";
export const FORM_DIGITAL_STORE_FT = "digital_store_ft";
export const FORM_ESTIMATION_FT = "estimation_ft";
export const FORM_EVENT_FT = "event_ft";
export const FORM_HONOR_FT = "honor_ft";
export const FORM_LIBRARY_FT = "library_ft";	
export const FORM_LOCATION_FT = "location_ft";
export const FORM_MESSAGE_FT = "message_ft";	
export const FORM_OFFER_FT = "offer_ft";
export const FORM_ORDER_FT = "order_ft";
export const FORM_ORGANIZATION_FT = "organization_ft";
export const FORM_PROCUREMENT_FT = "procurement_ft";
export const FORM_PRODUCT_FT = "product_ft";
export const FORM_PROVIDER_FT = "provider_ft";
export const FORM_TOPIC_FT = "topic_ft";
export const FORM_USER_FT = "user_instance_ft";
export const FORM_USER_GROUP_FT = "user_group_instance_ft";

export const FORM_AUTHORITY_AUTHOR_PLAIN = "authority_author_plain";
export const FORM_AUTHORITY_PUBLISHER_PLAIN = "authority_publisher_plain";
export const FORM_BOOK_PLAIN = "book_plain";
export const FORM_EBOOK_REPORT_PLAIN = "ebook_report_plain";
export const FORM_EBOOK_SUPPLIER_PLAIN = "ebook_supplier_plain";
export const FORM_OFFER_PLAIN = "offer_plain";
export const FORM_PRODUCT_PLAIN = "product_plain";

export const FORM_DBMANAGER = "dbmanager";
