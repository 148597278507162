import React, { createRef } from 'react';
import styles from './RateView.module.css';

import config from '../../config/config';

import ButtonIconText from '../button/ButtonIconText';
import UpdateGlobalIdModal from '../../modal/rateview/UpdateGlobalIdModal';

import { getCurrentResource } from '../../util/tools';
import { getButtonMode, getUserInfo } from '../../util/localStorage';
import { ENTITY_UPDATE_GLOBAL_ID_API_URL, performApiRequest } from '../../util/restapi';

import updateFieldIcon from '../../assets/icons/png24x24/Label.png';

class ButtonGlobalIdUpdate extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.ButtonGlobalIdUpdate;
		this.rateView = props.holder;
		this.refModal = React.createRef();
		this.messageHandler = {};
		this.userInfo = getUserInfo();
		this.useIcons = getButtonMode() == 'icon';
		this.state = {
			isModalOpen: false,
		}
	}
	
	updateGlobalId = async () => {
		var objects = this.rateView.getSelectedObjects();
		if( objects.length == 0 )
			return;
		if( objects.length == 1 ) {
			this.refModal.current.init( true );
			this.setState({ isModalOpen: true });
		} else {
			var request = {
				classId: this.rateView.formInfo.masterClass,
				listOldGlobalId: []
			}
			for(let objectMaster of objects)
				request.listOldGlobalId.push( objectMaster.globalId );
			var response = await performApiRequest( ENTITY_UPDATE_GLOBAL_ID_API_URL, request );
			if( response.success ) {
				var message = {
					mainMessage: this.resource.msgUpdated,
					maxWidth: '800px'
				};
			} else {
				var message = {
					mainMessage: response.message
				};
			}
			this.rateView.props.app.showMessage( message );
		}
	}
	closeModal = () => {
		this.setState({ isModalOpen: false });
		var modal = this.refModal.current; 
		if( modal.tryUpdate ) {
			var mainMessage = modal.updateResponse.message;
			if( modal.updateResponse.success && ! mainMessage )
				mainMessage = this.resource.msgUpdated; 
			var message = {
				title: modal.updateResponse.title,
				mainMessage: mainMessage,
				additionalMessage: modal.updateResponse.log,
				maxWidth: '800px'
			};
			this.rateView.props.app.showMessage( message );
		}
	}
	
	render() {
		if( this.props.holder.props.rvOptions.hideButtonGlobalIdUpdate )
			return null;
		var holder = this.props.holder;
		var userInfo = holder.userInfo;
		if( ! userInfo.permission.superuser )
			return null;

		return (
			<React.Fragment>
				<ButtonIconText 
					onClick={this.updateGlobalId}
					buttonType={'button'} useIcon={this.useIcons} styles={styles}
					text={this.resource.buttonUpdateGlobalId} 
					tooltip={this.resource.buttonUpdateGlobalIdTooltip} icon={updateFieldIcon}
				/>
				<UpdateGlobalIdModal ref={this.refModal} 
					isModalOpen={this.state.isModalOpen} 
					close={this.closeModal} 
					holder={this} rateView={this.props.holder} />
			</React.Fragment>
		);
	}
	
}

export default ButtonGlobalIdUpdate;
