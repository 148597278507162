import React from 'react';

import LabelField from '../../../components/plain/LabelField';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_Enum from '../../../components/plain/FieldList_Enum';
import FieldList_Strings from '../../../components/plain/FieldList_Strings';
import FieldList_MultilangString, {HEADER_POSITION_RIGHT} from '../../../components/plain/FieldList_MultilangString';
import FieldList_Reference from '../../../components/plain/FieldList_Reference';
import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';
import FieldList_CardsPanel, {DIRECTION_VERTICAL} from '../../../components/plain/FieldList_CardsPanel';

import {getLanguage} from '../../../util/localStorage';
import {getDefinedValue} from '../../../util/tools';
import { AUTHORITY_AUTHOR_PATH, TOPIC_PATH } from '../../../util/routes';
import { FORM_AUTHORITY_AUTHOR_TABLE, FORM_TOPIC_TABLE } from '../../../util/consts/forms';

class TabBrContent extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	resumeCardBuilder = (fieldResume, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var langText = plain.realm.getEnumerationItemText( plain.enumLanguage, fieldResume.value.language.value);
		if( langText )
			card += '[]'+ langText + '] ';
		
		card += getDefinedValue(fieldResume.value.text.value, '');
		
		if( card.length > 50 ) {
			card = card.substring(0, 47) + '...';
		}
		
		return card;
	} 
	fragmentCardBuilder = (fieldFragment, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var langText = plain.realm.getEnumerationItemText( plain.enumLanguage, fieldFragment.value.language.value);
		if( langText )
			card += '['+ langText + '] ';
		
		card += getDefinedValue(fieldFragment.value.text.value, '');
		
		if( card.length > 50 ) {
			card = card.substring(0, 47) + '...';
		}
		
		return card;
	} 
	aboutCardBuilder = (fieldAbout, index) => {
		var card = '';
		
		var currentLanguage = getLanguage();
		var name = null;
		for(let n of fieldAbout.value.name.value) {
			if( n.value.language.value == currentLanguage && 
					(n.value.shortName.value || n.value.fullName.value) ) 
			{
				name = n;
				break;
			}
		}
		if( name == null && fieldAbout.value.name.value.length > 0)
			name = fieldAbout.value.name.value[0];
		if( name ) {
			if( name.value.shortName.value )
				card += name.value.shortName.value;
			else if( name.value.fullName.value )
				card += name.value.fullName.value;
		}
		
		return card;
	}
	createInstitutionDataCellComponent = (columnId, index, instance, itemField, plain) => {
		if( columnId == 'name' ) {
			return (
				<FieldList_MultilangString
					instance={instance} path={'name'} parentField={itemField} plain={plain}
					labelEmptyToHeader={true} styles={{divMain: {padding: '5px'}}} ignoreTitle={true}
					headerPosition={HEADER_POSITION_RIGHT}
				/>
			);
		}
		return null;
	} 
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		let refAspectNodes = [
			{
				classId: 'Topic', plainFormPath: TOPIC_PATH, 
				selectInstanceFormId: FORM_TOPIC_TABLE,
				selectInstanceFormTitle: 'Теми'
			}
		];
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<FieldList_Enum
						instance={instance} path={'content/language'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
					<FieldList_Enum
						instance={instance} path={'content/original'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
					<FieldList_Enum
						instance={instance} path={'content/audience'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<div style={{flexGrow: '1', width: '35%', marginRight: '10px'}}>
						<FieldList_TableGroup instance={instance} path={'content/period'} parentField={parentField}
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} ignoreTitle={false} 
							table={{
								columns: [
									{ columnId: 'timeFrom', styles: {tdHeader: {width: '25%'}} },
									{ columnId: 'timeCodeFrom', styles: {tdHeader: {width: '25%'}} },
									{ columnId: 'timeUp', styles: {tdHeader: {width: '25%'}} },
									{ columnId: 'timeCodeUp', styles: {tdHeader: {width: '25%'}} },
								],
							}}
							styles={{
								table: {width: '100%'},
								trHeader: {height: '40px'}
							}}
						/>
					</div>
					<div style={{flexGrow: '1', width: '55%', marginLeft: '10px'}}>
						<FieldList_TableGroup instance={instance} path={'content/place'} parentField={parentField}
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} ignoreTitle={false} 
							table={{
								columns: [
									{ columnId: 'language', styles: {tdHeader: {width: '20%'}} },
									{ columnId: 'country', styles: {tdHeader: {width: '20%'}} },
									{ columnId: 'area', styles: {tdHeader: {width: '20%'}} },
									{ columnId: 'region', styles: {tdHeader: {width: '20%'}} },
									{ columnId: 'town', styles: {tdHeader: {width: '20%'}} },
								],
							}}
							styles={{
								table: {width: '100%'},
								trHeader: {height: '40px'}
							}}
						/>
					</div>
				</div>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<div style={{flexGrow: '1', width: '100%'}}>
						<FieldList_TableGroup instance={instance} path={'content/institution'} parentField={parentField}
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} ignoreTitle={false}
							createDataCellComponent = {this.createInstitutionDataCellComponent} 
							table={{
								columns: [
									{ columnId: 'level', styles: {tdHeader: {width: '12%'}} },
									{ columnId: 'name', styles: {tdHeader: {width: '88%'}} },
								],
							}}
							styles={{
								table: {width: '100%'},
								trHeader: {height: '40px'}
							}}
						/>
					</div>
				</div>
				<FieldList_CardsPanel path={'content/resume'} instance={instance} parentField={parentField} plain={plain} 
					itemComponent={ResumePanel} itemCardBuilder={this.resumeCardBuilder}
					labelEmptyToHeader={true}
					styles={{
						divMainStyle: {
							marginTop: '10px',
							padding: '10px 10px 10px 10px',
							backgroundColor: 'rgb(200,200,200)',
							border: '1px solid blue'
						},
						divCardsBlock: {
							marginRight: '10px',
							maxWidth: '300px',
							backgroundColor: 'rgb(200,200,200)',
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'rgb(230,230,230)',
							minHeight: '18px'
							
						}
					}}
				/>
				<FieldList_CardsPanel path={'content/fragment'} instance={instance} parentField={parentField} plain={plain} 
					itemComponent={FragmentPanel} itemCardBuilder={this.fragmentCardBuilder}
					labelEmptyToHeader={true}
					styles={{
						divMainStyle: {
							marginTop: '10px',
							padding: '10px 10px 10px 10px',
							backgroundColor: 'rgb(200,200,200)',
							border: '1px solid blue'
						},
						divCardsBlock: {
							marginRight: '10px',
							maxWidth: '300px',
							backgroundColor: 'rgb(200,200,200)',
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'rgb(230,230,230)',
							minHeight: '18px'
							
						}
					}}
				/>
				<FieldList_CardsPanel path={'content/about'} instance={instance} parentField={parentField} plain={plain} 
					itemComponent={AboutPanel} itemCardBuilder={this.aboutCardBuilder}
					labelEmptyToHeader={true}
					styles={{
						divMainStyle: {
							marginTop: '10px',
							padding: '10px 10px 10px 10px',
							backgroundColor: 'rgb(200,200,200)',
							border: '1px solid blue'
						},
						divCardsBlock: {
							marginRight: '10px',
							minWidth: '300px',
							maxWidth: '300px',
							backgroundColor: 'rgb(200,200,200)',
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'rgb(230,230,230)',
							minHeight: '18px'
							
						}
					}}
				/>
				<div style={{display: 'flex', marginTop: '10px'}}>
					<FieldList_Strings
						instance={instance} path={'content/keyword'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldList_Strings
						instance={instance} path={'content/reference'} parentField={parentField} plain={plain}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1'}}}
					/>
				</div>
				<FieldList_Reference path={'content/aspect'} parentField={parentField} instance={instance} plain={plain} 
					styles={{divMain: {marginTop: '10px'}}}
					labelEmptyToHeader={true}
					refNodes={refAspectNodes}
				/>
			</div>
		)
	}
}

class ResumePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', marginRight: '20px'}}
						/>
						<FieldEnum instance={instance} path={'format'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldString instance={instance} path={'part'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<LabelField instance={instance} path={'text'} parentField={fieldGroup} />
					<FieldString instance={instance} path={'text'} parentField={fieldGroup} plain={plain}
						multiline={true} rows={4} cols={60}
						styleInput={{width: '98%'}} ignoreTitle={true}
					 />
					<div style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
						<FieldString instance={instance} path={'uri'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}} styleDiv={{width: '100%'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

class FragmentPanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{maxWidth: '150px', marginRight: '20px'}}
						/>
						<FieldEnum instance={instance} path={'format'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldString instance={instance} path={'place'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<LabelField instance={instance} path={'text'} parentField={fieldGroup} />
					<FieldString instance={instance} path={'text'} parentField={fieldGroup} plain={plain}
						multiline={true} rows={4} cols={60}
						styleInput={{width: '98%'}} ignoreTitle={true}
					 />
				</div>
			</div>
		)
	}
}

class AboutPanel extends React.Component {

	refAboutAuthorityNodes = [
		{
			classId: 'AuthorityAuthor', plainFormPath: AUTHORITY_AUTHOR_PATH, 
			selectInstanceFormId: FORM_AUTHORITY_AUTHOR_TABLE,
			selectInstanceFormTitle: 'Авторитетні записи'
		}
	];
	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	nameCardBuilder = (fieldName, index) => {
		var plain = this.props.plain;
		var card = '';
		var language = fieldName.value.language.value;
		if( language ) {
			var languageText = plain.realm.getEnumerationItemText( plain.enumLanguage, language);
			card += languageText + '; ';
		}
		if( fieldName.value.shortName.value )
			card += fieldName.value.shortName.value;
		else if( fieldName.value.fullName.value )
			card += fieldName.value.fullName.value;
		if( ! card )
			card = '...'
		return card;
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		var refSubjectAuthorityNodes = [
			{
				classId: 'AuthorityAuthor', plainFormPath: AUTHORITY_AUTHOR_PATH, 
				selectInstanceFormId: FORM_AUTHORITY_AUTHOR_TABLE,
				selectInstanceFormTitle: 'Авторитетні записи'
			}
		];
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'dates'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<FieldList_CardsPanel path={'name'} instance={instance} parentField={fieldGroup} plain={plain} 
						itemComponent={NamePanel} itemCardBuilder={this.nameCardBuilder}
						direction={DIRECTION_VERTICAL}
						styles={{
							divMainStyle: {
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue',
								marginBottom: '10px'
							},
							divCardsBlock: {
								backgroundColor: 'rgb(200,200,200)',
								minWidth: '300px'
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
							}
						}}
					/>
					<FieldList_Reference path={'subjectAuthority'} instance={instance} parentField={fieldGroup} plain={plain} 
						styles={{divMain: {marginTop: '10px'}}}
						labelEmptyToHeader={true}
						refNodes={refSubjectAuthorityNodes}
					/>
				</div>
			</div>
		)
	}
}

class NamePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}

	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '5px 0 0 0', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1', marginRight: '20px'}}
						/>
						<FieldEnum instance={instance} path={'nameType'} parentField={fieldGroup} plain={plain}
							styleSelect={{flexGrow: '1'}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'keyName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'name'} parentField={fieldGroup} plain={plain}
							styleInput={{maxWidth: '170px', flexGrow: '1', marginRight: '20px'}}
						 />
						<FieldString instance={instance} path={'secondName'} parentField={fieldGroup} plain={plain}
							styleInput={{maxWidth: '170px', flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'shortName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<FieldString instance={instance} path={'fullName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'place'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1', marginRight: '30px'}}
						 />
						<FieldString instance={instance} path={'affilation'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrContent; 