import React from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage from '../../components/plain/PlainPage';
import LabelField from '../../components/plain/LabelField';
import FieldEnum from '../../components/plain/FieldEnum';
import FieldReference from '../../components/plain/FieldReference';
import FieldString from '../../components/plain/FieldString';
import FieldList_TableGroup from '../../components/plain/FieldList_TableGroup';
import FieldList_CardsPanel from '../../components/plain/FieldList_CardsPanel';

import { ORGANIZATION_PATH } from '../../util/routes';
import { FORM_ORGANIZATION_TABLE } from '../../util/consts/forms';
import { getUserInfo } from '../../util/localStorage';

class PlainEBookSupplierPage extends PlainPage { 

	userInfo = null;
		
	constructor(props) {
		super(props);
		this.formPath = 'ebook_supplier_plain';
		this.formTitle = 'Розповсюджувач за програмою "єКнига"';
		this.userInfo = getUserInfo();
	}
	componentDidMount = async () => {
		this.init('EBookSupplier')
	}
	completeInit = async () => {
		if( this.instance.id == null ) {
			this.instance.rootField.value.state.value = 'registered';
		}
		this.instanceReadOnly =
			( ! this.userInfo.permission.superuser ) && 
			( this.instance.rootField.value.state.value === 'accepted' )
		return true;
	}

	merchantCardBuilder = (fieldMerchant, index) => {
		let card = '';
		if( fieldMerchant.value.aid.value )
			card += fieldMerchant.value.aid.value;
		card += ' - '
		if( fieldMerchant.value.mid.value )
			card += fieldMerchant.value.mid.value;
		card += ' - '
		if( fieldMerchant.value.mcc.value )
			card += fieldMerchant.value.mcc.value;
		return card;
	}

	renderBody() {
		var refOrganizationNodes = [
			{
				classId: 'Organization', plainFormPath: ORGANIZATION_PATH, 
				selectInstanceFormId: FORM_ORGANIZATION_TABLE,
				selectInstanceFormTitle: 'Організація'
			}
		]
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FieldReference instance={this.state.instance} path={'/orgRef'} plain={this}
							refNodes={refOrganizationNodes} 
							styles={{divMain: {marginRight: '30px'}}}
							readOnly={ ! this.userInfo.permission.superuser && this.instanceReadOnly } 
						/>
						<FieldEnum instance={this.state.instance} path={'/state'} plain={this} styleSelect={{}}
							readOnly={ ! this.userInfo.permission.superuser } 
						/>
					</div>
					
					<div style={{marginTop: '20px', marginRight: '10px'}}>
						<FieldList_CardsPanel path={'/merchants'} instance={this.instance}
							plain={this} itemComponent={MerchantPanel} disableMoveItems={true}
							itemCardBuilder={this.merchantCardBuilder}
							readOnly={ ! this.userInfo.permission.superuser && this.instanceReadOnly } 
							styles={{
								divCardsBlock: {
									marginRight: '10px',
									minWidth: '400px',
									maxWidth: '400px',
								},
								divCardList: {
									maxHeight: '400px',
									overflowY: 'scroll'
								},
								divCard: {
									marginTop: '3px',
									padding: '7px',
									cursor: 'pointer',
									minHeight: '18px'
								}
							}}
					/>
					</div>
					
					<div style={{marginTop: '10px'}}>
						<div>
							<LabelField instance={this.state.instance} path={'/comment'} />
						</div>
						<div>
							<FieldString instance={this.state.instance} path={'/comment'} plain={this}
								multiline={true} rows={5} cols={60} styleInput={{width: '99%'}}
								readOnly={ ! this.userInfo.permission.superuser && this.instanceReadOnly } 
							 />
						</div>
					</div>			
				</div>
			</div>
		)
	}
}

class MerchantPanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{border: '1px solid green', margin: '10px 10px 10px 10px', padding: '10px'}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FieldEnum path={'aid'} instance={instance} parentField={fieldGroup} plain={plain}
							styles={{ select: { width: '370px', marginRight: '20px'}} }
							readOnly={ ! plain.userInfo.permission.superuser && plain.instanceReadOnly } 
						 />
						<FieldString path={'mid'} instance={instance} parentField={fieldGroup} plain={plain}
							styles={{ input: { width: '170px', marginRight: '20px'}} }
							readOnly={ ! plain.userInfo.permission.superuser && plain.instanceReadOnly } 
						 />
						<FieldEnum path={'mcc'} instance={instance} parentField={fieldGroup} plain={plain} 
							styles={{ select: { width: '100px'} }} 
							readOnly={ ! plain.userInfo.permission.superuser && plain.instanceReadOnly } 
						/>
					</div>
					<div style={{marginTop: '15px'}}>
						<FieldList_TableGroup instance={instance} path={'terminals'} parentField={fieldGroup}
							plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} disableMoveItems={true} 
							readOnly={ ! plain.userInfo.permission.superuser && plain.instanceReadOnly } 
							table={{
								columns: [
									{ columnId: 'tid', styles: {tdHeader: {width: '20%'}} },
									{ columnId: 'area', styles: {tdHeader: {width: '30%'}} },
									{ columnId: 'address', styles: {tdHeader: {width: '50%'}} },
								],
							}}
							styles={{
								table: {width: '100%'},
								trHeader: {height: '40px'}
							}}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default PlainEBookSupplierPage;
