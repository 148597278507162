import React, {createRef} from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage, {FORM_STATE_INIT_ERROR} from '../../components/plain/PlainPage';
import LabelField from '../../components/plain/LabelField';
import FieldString from '../../components/plain/FieldString';
import FieldInteger from '../../components/plain/FieldInteger';
import FieldDecimal from '../../components/plain/FieldDecimal';
import FieldDatetime from '../../components/plain/FieldDatetime';
import FieldEnum from '../../components/plain/FieldEnum';
import FieldReference from '../../components/plain/FieldReference';
import FieldList_TableGroup from '../../components/plain/FieldList_TableGroup';

import {getCurrentResource, formatDateTime} from '../../util/tools';
import {FORM_EBOOK_REPORT_PLAIN, FORM_EBOOK_SUPPLIER_TABLE, FORM_BOOK_TABLE} from '../../util/consts/forms';
import {EBOOK_SUPPLIER_PLAIN_PATH, BOOK_PATH} from '../../util/routes';
import {performApiRequest, ACTION_EBOOK_REPORT_GET_SUPPLIER_API_URL, ENTITY_LOAD_API_URL} from '../../util/restapi'; 
import {IET_ADD_LIST_ITEM, IET_SET_VALUE} from '../../util/realm/instance';

import iconAdd from '../../assets/icons/png24x24/Add.png';
import iconClone from '../../assets/icons/png24x24/edit-multi.png';
import iconDelete from '../../assets/icons/png24x24/Delete.png';

class PlainEBookReportPage extends PlainPage {

	resourceReportPage = null;
	refSupplierNodes = [
		{
			classId: 'EBookSupplier', plainFormPath: EBOOK_SUPPLIER_PLAIN_PATH, 
			selectInstanceFormId: FORM_EBOOK_SUPPLIER_TABLE,
			selectInstanceFormTitle: 'Розповсюджувач'
		}
	];
	
	constructor(props) {
		super(props);
		this.resourceReportPage = getCurrentResource().pages.plain.PlainEBookReportPage;
		this.formPath = FORM_EBOOK_REPORT_PLAIN;
		this.formTitle = 'Звіт розповсюджувача за програмою "єКнига"';
		this.refAmount = createRef();
	}
	componentDidMount = async () => {
		this.init('EBookReport')
	}
	completeInit = async () => {
		var beanSupplier = null;
		if( this.instance.id == null ) {
			this.instance.rootField.value.dateTime.value = formatDateTime( new Date() );
			var supplierRequest = {
				type: 'initial'
			}
			var response = await performApiRequest(ACTION_EBOOK_REPORT_GET_SUPPLIER_API_URL, supplierRequest);
			if( ! response.success ) {
				this.setState({formState: FORM_STATE_INIT_ERROR, message: response.message});
				return false;
			}
			beanSupplier = response.data;
			if( beanSupplier != null ) {
				var refSupplier = {
					id: beanSupplier.id,
					globalId: beanSupplier.globalId,
					className: beanSupplier.className,
					card: beanSupplier.cardMaster
				};
				this.instance.rootField.value.supplierRef.value = refSupplier;
			} 
		} else {
			var supplierRef = this.instance.rootField.value.supplierRef.value;
			if( supplierRef != null ) {
				var response = await performApiRequest(ENTITY_LOAD_API_URL, {id: supplierRef.id});
				if( ! response.success ) {
					this.setState({formState: FORM_STATE_INIT_ERROR, message: response.message});
					return false;
				}
				beanSupplier = response.data.entity;
			}
			this.recalculateSum();
		}
		console.log('Supplier', beanSupplier);
		this.state.supplier = beanSupplier;
		this.state.isSupplierRefReadOnly = this.instance.rootField.value.reportItem.value.length > 0;
		this.recalculateSum();
		return true;
	}
	
	createItemCell = (columnId, rowIndex, instance, fieldGroup, plain) => {

		if( columnId == 'mid' ) {
			let enumerationMids = { id: 'mids', items: [] };
			let wrongValues = [];
			let supplier = plain.state.supplier;
			if( supplier != null ) {
				for(let m of supplier.merchants) {
					enumerationMids.items.push( {value: m.mid, label: m.mid} )
				}
			}
			if( ! instance.rootField.value.reportItem.value[rowIndex] ) {
				console.log( 'Empty mid', instance, rowIndex );
			}
			let mid = instance.rootField.value.reportItem.value[rowIndex].value.mid.value;
			if( mid ) {
				let valid = false;
				for( let e of enumerationMids.items) {
					if( e.value == mid ) {
						valid = true;
						break;
					}
				}
				if( ! valid ) {
					enumerationMids.items.unshift({value: mid, label: mid});
					wrongValues.push( mid );
				}
			}
			enumerationMids.items.unshift({value: '', label: ''})
			return (
				<FieldEnum instance={instance} path={'mid'} plain={plain} parentField={fieldGroup} 
					enumeration={enumerationMids} wrongValues={wrongValues} 
					styles={{ select: {width: '95%'}}}  ignoreTitle={true}/>
			);
		}
		if( columnId == 'tid' ) {
			let enumerationTids = { id: 'tids', items: [] };
			let wrongValues = [];
			let supplier = plain.state.supplier;
			if( supplier != null ) {
				let mid = instance.rootField.value.reportItem.value[rowIndex].value.mid.value;
				for(let m of supplier.merchants) {
					if( m.mid == mid ) {
						for(let t of m.terminals ) {
							enumerationTids.items.push( {value: t.tid, label: t.tid} )
						}
					}
				}
			}
			let tid = instance.rootField.value.reportItem.value[rowIndex].value.tid.value;
			if( tid ) {
				let valid = false;
				for( let e of enumerationTids.items) {
					if( e.value == tid ) {
						valid = true;
						break;
					}
				}
				if( ! valid ) {
					enumerationTids.items.unshift({value: tid, label: tid});
					wrongValues.push( tid );
				}
			}
			enumerationTids.items.unshift({value: '', label: ''});
			return (
				<FieldEnum instance={instance} path={'tid'} plain={plain} parentField={fieldGroup} 
					enumeration={enumerationTids} wrongValues={wrongValues}
					styles={{ select: {width: '95%'}}}  ignoreTitle={true}
				/>
			);
		}
		return null;
	}
	
	handleInstanceChange = async (event) => {
		super.handleInstanceChange(event);
		let field = event.field;
		
		let fieldReportItem = this.instance.rootField.value.reportItem;
		if( event.eventType == IET_ADD_LIST_ITEM && field == fieldReportItem) {
			let index = fieldReportItem.value.length-1;
			let addedRow = fieldReportItem.value[index];
			if( ! addedRow.value.dateTime.value )
				addedRow.value.dateTime.value = this.instance.rootField.value.dateTime.value;
			addedRow.value.qty.value = '';
			addedRow.value.price.value = '';
		}
		
		let fieldRefSupplier = this.instance.rootField.value.supplierRef;
		let beanSupplier = null;
		if( event.eventType == IET_SET_VALUE && field == fieldRefSupplier ) {
			let supplierRef = fieldRefSupplier.value;
			if( supplierRef != null ) {
				let response = await performApiRequest(ENTITY_LOAD_API_URL, {id: supplierRef.id});
				if( response.success ) {
					beanSupplier = response.data.entity;
				}
			}
			this.setState({supplier: beanSupplier})
		}

		if( event.eventType == IET_SET_VALUE && field.model.fieldId == 'mid' ) {
			let supplier = beanSupplier;
			if( ! supplier )
				supplier = this.state.supplier;
			if( supplier != null ) {
				let mid = field.value;
				for(let m of supplier.merchants) {
					if( m.mid == mid ) {
						if( m.terminals.length == 1 && ! field.parent.value.tid.value ) {
							let tid = m.terminals[0].tid;
							field.parent.value.tid.value = tid; 
						}
					}
				}
			}
		}
		
		this.setState({
			isSupplierRefReadOnly: this.instance.rootField.value.reportItem.value.length > 0,
			plainDirtyCounter: this.state.plainDirtyCounter + 1 
		});
		
		let recalcSum = false;
		while( field != null ) {
			if( field == fieldReportItem ) {
				recalcSum = true;
				break;
			} else {
				field = field.parent;
			}
		}
		if( recalcSum )
			this.recalculateSum();
	}
	recalculateSum = () => {
		let validSum = true;
		let sum = 0;
		for(let item of this.instance.rootField.value.reportItem.value) {
			let qty = item.value.qty.value;
			let price = item.value.price.value;
			if( qty==null || price==null || Number(price) == NaN) {
				validSum = false;
				break;
			}
			//if( item.value.qty.value && price && Number(price) != NaN ) {
				//sum += item.value.qty.value * item.value.price.value;
				sum += Number(price);
			//}
		}
		if( validSum )
			this.instance.setFieldValue(this.instance.rootField.value.amount, sum);
		else
			this.instance.setFieldValue(this.instance.rootField.value.amount, '');
		if( this.refAmount.current )
			this.refAmount.current.setState({valueIsValid: validSum});
	}

	renderBody() {
		let instance = this.state.instance;
		
		console.log( 'render Report', instance )
		
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FieldDatetime instance={instance} path={'/dateTime'} plain={this} 
							styleInput={{width: '200px', marginRight: '20px'}} 
						/>
						<FieldDecimal ref={this.refAmount} instance={instance} path={'/amount'} plain={this} readOnly={true}
							styleInput={{width: '100px', marginRight: '20px'}} 
						/>
						<FieldReference instance={instance} path={'/supplierRef'} plain={this}
							refNodes={this.refSupplierNodes}
							readOnly={this.state.isSupplierRefReadOnly}
							styles={{divMain: {flexGrow: '1'}}}
						/>
					</div>
					<div style={{marginTop: '20px'}}>
						<FieldList_TableGroup instance={instance} path={'/reportItem'}
							plain={this} plainDirtyCounter={this.state.plainDirtyCounter}
							header={ItemTableHeader} createDataCellComponent={this.createItemCell} 
							table={{
								columns: [
									{ columnId: 'dateTime', styles: {tdHeader: {width: '12%'} } }, 
									{ columnId: 'aid', styles: {tdHeader: {width: '7%'} } },
									{ columnId: 'mid', styles: {tdHeader: {width: '11%'} } },
									{ columnId: 'mcc', styles: {tdHeader: {width: '6%'} } },
									{ columnId: 'tid', styles: {tdHeader: {width: '9%'} } },
									{ columnId: 'bin', styles: {tdHeader: {width: '6%'} } },
									{ columnId: 'operation', styles: {tdHeader: {width: '8%'} } },
									{ columnId: 'tranId', styles: {tdHeader: {width: '7%'} } },
									{ columnId: 'qty', styles: {tdHeader: {width: '2%'} } },  
									{ columnId: 'price', styles: {tdHeader: {width: '4%'} } },
									{ columnId: 'bookRef', styles: {tdHeader: {width: '24%'} },
										refNodes: [
											{
												classId: 'Book', plainFormPath: BOOK_PATH, 
												selectInstanceFormId: FORM_BOOK_TABLE,
												selectInstanceFormTitle: 'Книга'
											}
										]
									},
								],
							}}
							styles={{
								table: {width: '100%'},
								trHeader: {height: '40px'}
							}}
						/>
					</div>
					<div style={{marginTop: '20px', paddingRight: '9px'}}>
						<div>
							<LabelField instance={this.state.instance} path={'/comment'} />
						</div>
						<div>
							<FieldString instance={this.state.instance} path={'/comment'} plain={this}
								multiline={true} rows={5} cols={60} styles={{input: {width: '100%'}}}
							 />
						</div>
					</div>			
				</div>
			</div>
		)
	}
}

class ItemCell extends React.Component {

	refBookNodes = [
		{
			classId: 'Book', plainFormPath: BOOK_PATH, 
			selectInstanceFormId: FORM_BOOK_TABLE,
			selectInstanceFormTitle: 'Книга'
		}
	];
	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'dateTime' ) {
			return <FieldDatetime instance={this.props.instance} path={'dateTime'} plain={plain} parentField={fieldGroup} ignoreTitle={true} styleInput={{width: '95%'}} />
		}
		if( columnId == 'mid' ) {
			let enumerationMids = { id: 'mids', items: [] };
			let wrongValues = [];
			let supplier = plain.state.supplier;
			if( supplier != null ) {
				for(let m of supplier.merchants) {
					enumerationMids.items.push( {value: m.mid, label: m.mid} )
				}
			}
			let rowIndex = this.props.rowIndex;
			let mid = plain.instance.rootField.value.reportItem.value[rowIndex].value.mid.value;
			if( mid ) {
				let valid = false;
				for( let e of enumerationMids.items) {
					if( e.value == mid ) {
						valid = true;
						break;
					}
				}
				if( ! valid ) {
					enumerationMids.items.unshift({value: mid, label: mid});
					wrongValues.push( mid );
				}
			}
			enumerationMids.items.unshift({value: '', label: ''})
			return (
				<FieldEnum instance={this.props.instance} path={'mid'} plain={plain} parentField={fieldGroup} 
					enumeration={enumerationMids} wrongValues={wrongValues} 
					styleInput={{width: '95%'}}  ignoreTitle={true}/>
			);
		}
		if( columnId == 'tid' ) {
			let enumerationTids = { id: 'tids', items: [] };
			let wrongValues = [];
			let supplier = plain.state.supplier;
			let rowIndex = this.props.rowIndex;
			if( supplier != null ) {
				let mid = plain.instance.rootField.value.reportItem.value[rowIndex].value.mid.value;
				for(let m of supplier.merchants) {
					if( m.mid == mid ) {
						for(let t of m.terminals ) {
							enumerationTids.items.push( {value: t.tid, label: t.tid} )
						}
					}
				}
			}
			let tid = plain.instance.rootField.value.reportItem.value[rowIndex].value.tid.value;
			if( tid ) {
				let valid = false;
				for( let e of enumerationTids.items) {
					if( e.value == tid ) {
						valid = true;
						break;
					}
				}
				if( ! valid ) {
					enumerationTids.items.unshift({value: tid, label: tid});
					wrongValues.push( tid );
				}
			}
			enumerationTids.items.unshift({value: '', label: ''});
			return (
				<FieldEnum instance={this.props.instance} path={'tid'} plain={plain} parentField={fieldGroup} 
					enumeration={enumerationTids} wrongValues={wrongValues}
					styleInput={{width: '95%'}}  ignoreTitle={true}
				/>
			);
		}
		if( columnId == 'tranId' ) {
			return ( 
				<FieldString instance={this.props.instance} path={'tranId'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}} />
			);
		}
		if( columnId == 'qty' ) {
			return ( 
				<FieldInteger instance={this.props.instance} path={'qty'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}}/>
			);
		}
		if( columnId == 'price' ) {
			return ( 
				<FieldDecimal instance={this.props.instance} path={'price'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}}/>
			);
		}
		if( columnId == 'bookRef' ) {
			return (
				<FieldReference instance={this.props.instance} path={'bookRef'} plain={plain} parentField={fieldGroup}
					refNodes={this.refBookNodes} ignoreTitle={true}
					styles={{
						divMain: {width: '99%', marginLeft: '10px', alignItems: 'start'}, 
						divEditTools: {minWidth: '90px'}
					}}
				/>
			);
		}
		return null;
	}

}

class ItemTableHeader extends React.Component {

	/*
		table
	*/
	constructor(props) {
		super(props);
	}

	addItem = () => {
		var plain = this.props.table.props.plain;
		var supplier = plain.state.supplier;
		if( supplier == null ) {
			var resource = plain.resourceReportPage;
			plain.props.app.showMessage({mainMessage: resource.msgSelectSupplier});
			return;
		}

		var instance = this.props.table.props.instance;
		var fieldList = instance.rootField.value.reportItem;
		var newItem = instance.createInstanceField(fieldList.model.item, null);
		if( supplier.merchants.length == 1 ) {
			var merch = supplier.merchants[0]; 
			newItem.value.mid.value = merch.mid;
			if( merch.terminals.length == 1 ) {
				newItem.value.tid.value = merch.terminals[0].tid;
			}
		}
		instance.addListItem(fieldList, newItem);
	}
	addItemClone = () => {
		var plain = this.props.table.props.plain;
		var supplier = plain.state.supplier;
		if( supplier == null ) {
			var resource = plain.resourceReportPage;
			plain.props.app.showMessage({mainMessage: resource.msgSelectSupplier});
			return;
		}
		
		var index = this.props.table.state.selectedIndex;
		if( index < 0 )
			return;
		
		var instance = this.props.table.props.instance;
		var fieldList = instance.rootField.value.reportItem;
		var original = fieldList.value[index];
		var clone = instance.createInstanceField(fieldList.model.item, null);
		clone.value.dateTime.value = original.value.dateTime.value;
		clone.value.mid.value = original.value.mid.value;
		clone.value.tid.value = original.value.tid.value;
		clone.value.tranId.value = original.value.tranId.value;
		
		instance.addListItem(fieldList, clone);
	}

	render() {
		let resource = this.props.table.props.plain.resourceReportPage;
		return(
			<div style={{marginBottom: '5px'}}>
				<button type='button' className={stylesPlain.buttonIcon}
					onClick={this.addItem} title={resource.buttonAdd}
				>
					<img src={iconAdd} />
				</button>
				<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px'}}
					onClick={ this.addItemClone} title={resource.buttonClone}
				>
					<img src={iconClone} />
				</button>
				<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '15px'}}
					onClick={this.props.table.deleteItem}
				>
					<img src={iconDelete} />
				</button>
			</div>
		);
	}
}

export default PlainEBookReportPage;
