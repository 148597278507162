import React, { Component } from 'react';

import NavBar from '../../components/navigation/NavBar';

import { getCurrentResource } from '../../util/tools';
import { 
	DBMANAGER_API_URL, DBMANAGER_GET_DB_ACCESS_API_URL, DBMANAGER_SET_DB_ACCESS_API_URL, 
	performApiRequest 
} from '../../util/restapi';
import { LIST_CLASSES } from '../../util/model';

const FORM_STATE_GET_DATA = 'gd';
const FORM_STATE_WAITING = 'w';

const TAB_COMMON = 'common';
const TAB_OBJECTS = 'objects';
const TAB_CARDS = 'cards';
const TAB_LINKS = 'links';
const TAB_FILES = 'files';
const TAB_WORDS = 'words';

class DbManagerPage extends Component {
	
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.dbmanager.DbManagerPage;
		this.state = {
			dirty: false,
			activeTab: TAB_COMMON,
			formState: FORM_STATE_GET_DATA,
			dbAccessMode: '...'
		};
		this.initNewTask();
		
		this.listObjectClasses = [];
		this.listCardClasses = [];
		this.listLinkClasses = [];
		this.listFileLinkClasses = [];
		this.listWordClasses = [];
		for(let cls of LIST_CLASSES) {
			this.listObjectClasses.push(
				{
					checked: false,
					class: cls
				} 
			);
			this.listCardClasses.push(
				{
					checked: false,
					class: cls
				} 
			);
			if( cls.hasLinks ) {
				this.listLinkClasses.push(
					{
						checked: false,
						class: cls
					} 
				);
			}
			if( cls.hasFileLinks ) {
				this.listFileLinkClasses.push(
					{
						checked: false,
						class: cls
					} 
				);
			}
			if( cls.hasWords ) {
				this.listWordClasses.push(
					{
						checked: false,
						class: cls
					} 
				);
			}
		}
	}
	initNewTask = () => {
		this.task = {
			patchId: '',
			syncKnr: false,
			syncKnrFromDate: null,
			resolveReference: false,
			deleteUnusedFiles: false,
			clearUnresolvedTerminals: false,
			clearUnresolvedReportItems: false,
			listObjectClass: [],
			downloadImages: false,
			listCardClass: [],
			listLinkClass: [],
			listFileLinkClass: [],
			listWordClass: []
		};
	}
	componentDidMount = async () => {
		const request = {
			taskId: 'getDbAccessMode'
		};
		var response = await performApiRequest( DBMANAGER_GET_DB_ACCESS_API_URL, request );
		if( ! response.success ) {
			this.props.app.showResponse( response );
		} else {
			this.setState({dbAccessMode: response.data})
		}
	}
	setDbAccessMode = async (mode) => {
		const request = {
			dbAccess: mode
		};
		var response = await performApiRequest( DBMANAGER_SET_DB_ACCESS_API_URL, request );
		if( ! response.success ) {
			this.props.app.showResponse( response );
		} else {
			this.setState({dbAccessMode: mode})
		}
	}

	setActiveTab = (tabId) => {
		this.setState({activeTab: tabId});
	}
		
	handleValueChange = (e) => {
		const { name, value } = e.target;
		if( name == 'patch' )
			this.task.patchId = value;
		this.setState({dirty: true});
	}
	handleSyncKnrFromDateValueChange = e => {
		var value = e.target.valueAsNumber;
		if( isNaN(value) )
			value = null;
		this.task.syncKnrFromDate = value;
		this.setState({ dirty: true });
	};
	handleSyncKnrCheckBoxClick = (e) => {
		var checked = e.target.checked;
		this.task.syncKnr = checked;
		this.setState({dirty: true});
	}
	handleCommonCheckBoxClick = (e) => {
		const { name, checked } = e.target;
		if( name == 'cbResolveReference' )
			this.task.resolveReference = checked;
		if( name == 'cbDeleteUnusedFiles' )
			this.task.deleteUnusedFiles = checked;
		if( name == 'cbClearUnresolvedTerminals' )
			this.task.clearUnresolvedTerminals = checked;
		if( name == 'cbClearUnresolvedReportItems' )
			this.task.clearUnresolvedReportItems = checked;
		this.setState({dirty: true});
	}
	handleObjectCheckBoxClick = (e) => {
		const { name, checked } = e.target;
		for(let c of this.listObjectClasses) {
			if( c.class.id == name ) {
				c.checked = checked;
				break;
			}
		}
		this.setState({dirty: true});
	}
	handleDownloadImagesCheckBoxClick = (e) => {
		var checked = e.target.checked;
		this.task.downloadImages = checked;
		this.setState({dirty: true});
	}
	handleCardCheckBoxClick = (e) => {
		const { name, checked } = e.target;
		for(let c of this.listCardClasses) {
			if( c.class.id == name ) {
				c.checked = checked;
				break;
			}
		}
		this.setState({dirty: true});
	}
	handleLinkCheckBoxClick = (e) => {
		const { name, checked } = e.target;
		for(let c of this.listLinkClasses) {
			if( c.class.id == name ) {
				c.checked = checked;
				break;
			}
		}
		this.setState({dirty: true});
	}
	handleFileLinkCheckBoxClick = (e) => {
		const { name, checked } = e.target;
		for(let c of this.listFileLinkClasses) {
			if( c.class.id == name ) {
				c.checked = checked;
				break;
			}
		}
		this.setState({dirty: true});
	}
	handleWordCheckBoxClick = (e) => {
		const { name, checked } = e.target;
		for(let c of this.listWordClasses) {
			if( c.class.id == name ) {
				c.checked = checked;
				break;
			}
		}
		this.setState({dirty: true});
	}
	
	performTask = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		for(let c of this.listObjectClasses) {
			if( c.checked )
				this.task.listObjectClass.push( c.class.id );
		}
		for(let c of this.listCardClasses) {
			if( c.checked )
				this.task.listCardClass.push( c.class.id );
		}
		for(let c of this.listLinkClasses) {
			if( c.checked )
				this.task.listLinkClass.push( c.class.id );
		}
		for(let c of this.listFileLinkClasses) {
			if( c.checked )
				this.task.listFileLinkClass.push( c.class.id );
		}
		for(let c of this.listWordClasses) {
			if( c.checked )
				this.task.listWordClass.push( c.class.id );
		}
		var response = await performApiRequest(DBMANAGER_API_URL, this.task);

		this.initNewTask();
		this.setState({dirty: false, formState: FORM_STATE_GET_DATA});

		this.props.app.showResponse( response, 'Work complete' );
	}
		
	render() {
		var divWaiting = (null);
		var disabled = false;
		if( this.state.formState == FORM_STATE_WAITING ) {
			disabled = true;
			divWaiting = (
				<div style={{textAlign: 'center', marginTop: '15px'}}>
					{this.resource.labelWait}
				</div>
			)
		}
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<div style={localStyles.divMainStyle}>
					<div style={{marginBottom: '15px'}}>
						{this.resource.title}
					</div>
					<Tabs holder={this} />
					<div style={{padding: '10px', background: '#f8f8f8', textAlign: 'left'}}>
						<TabBody holder={this} disabled={disabled} />
					</div>					
					{divWaiting}
					<div style={{marginTop: '10px', textAlign: 'right'}}>
						<button type='button' onClick={this.performTask} disabled={disabled}>{this.resource.buttonPerform}</button>
					</div>
				</div>
			</React.Fragment>
		)
	}
	
}

function Tabs(props) {
	var holder=props.holder;
	var res = holder.resource;
	return (
		<div style={{display: 'flex', background: 'lightgray', padding: '10px 10px 0 10px'}}>
			<Tab holder={holder} id={TAB_COMMON} title={res.tabCommon} />
			<Tab holder={holder} id={TAB_OBJECTS} title={res.tabObjects} />
			<Tab holder={holder} id={TAB_CARDS} title={res.tabCards} />
			<Tab holder={holder} id={TAB_LINKS} title={res.tabLinks} />
			<Tab holder={holder} id={TAB_FILES} title={res.tabFiles} />
			<Tab holder={holder} id={TAB_WORDS} title={res.tabWords} />
		</div>
	);
}

function Tab(props) {
	var tabId = props.id; 
	if( tabId == props.holder.state.activeTab )
		var tabStyle = localStyles.divActiveTab;
	else
		var tabStyle = localStyles.divNotActiveTab;
	return (
		<div style={tabStyle} onClick={ ()=>{props.holder.setActiveTab(tabId)} }>
			{props.title}
		</div>
	);
}

function TabBody(props) {
	var tabId = props.holder.state.activeTab;
	
	if( tabId == TAB_COMMON )
		return ( <TabCommon holder={props.holder} disabled={props.disabled} /> );
	if( tabId == TAB_OBJECTS )
		return ( <TabObjects holder={props.holder} disabled={props.disabled} /> );
	if( tabId == TAB_CARDS )
		return ( <TabCards holder={props.holder} disabled={props.disabled} /> );
	if( tabId == TAB_LINKS )
		return ( <TabLinks holder={props.holder} disabled={props.disabled} /> );
	if( tabId == TAB_FILES )
		return ( <TabFiles holder={props.holder} disabled={props.disabled} /> );
	if( tabId == TAB_WORDS )
		return ( <TabWords holder={props.holder} disabled={props.disabled} /> );
}

function TabCommon(props) {
	var holder = props.holder;
	var res = holder.resource;
	var task = holder.task;
	
	var syncKnrFromDate = task.syncKnrFromDate;
	if( syncKnrFromDate != null ) {
		syncKnrFromDate = new Date( syncKnrFromDate )
		syncKnrFromDate = syncKnrFromDate.toISOString().substring(0,10);
	}
	/*
			<div style={{marginTop: '20px'}}>
				<input type='checkbox' checked={task.syncKnr} 
					onChange={holder.handleSyncKnrCheckBoxClick} 
				/> Load data from KNR
				<span style={{marginLeft: '30px'}}>From date</span>
				<input type='date' value={syncKnrFromDate}
					onChange={holder.handleSyncKnrFromDateValueChange}
				/>
			</div>
	*/
	
	return (
		<div>
			<div style={{display: 'flex'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid blue', width: 'calc(50% - 42px)'}}>
					<div>
						<input type='checkbox' checked={task.resolveReferences}
							name='cbResolveReference' 
							onChange={holder.handleCommonCheckBoxClick} 
						/> {res.cbResolveReferences}
					</div>
					<div style={{marginTop: '5px'}}>
						<input type='checkbox' checked={task.deleteUnusedFiles}
							name='cbDeleteUnusedFiles' 
							onChange={holder.handleCommonCheckBoxClick} 
						/> {res.cbDeleteNotUsedFiles}
					</div>
					<div style={{marginTop: '10px'}}>
						<div>Вилучити об'єкти з "висячими" посиланнями</div>
						<div style={{marginLeft: '10px'}}>
							<div style={{marginTop: '5px'}}>
								<input type='checkbox' checked={task.clearUnresolvedTerminals} 
									name='cbClearUnresolvedTerminals' 
									onChange={holder.handleCommonCheckBoxClick} 
								/> Термінали 
							</div>
							<div style={{marginTop: '5px'}}>
								<input type='checkbox' checked={task.clearUnresolvedReportItems} 
									name='cbClearUnresolvedReportItems' 
									onChange={holder.handleCommonCheckBoxClick} 
								/> Елементи звітів 
							</div>
						</div>
					</div>
				</div>
				<div style={{margin: '10px', padding: '10px', border: '1px solid blue', width: 'calc(50% - 42px)'}}>
					<div>
						{res.labelPatch}:&nbsp; 
						<input type='text' name='patch' value={task.patchId}
							onChange={holder.handleValueChange}
							disabled={props.disabled} size={40}
						/>
					</div>
				</div>
			</div>
			<div style={{margin: '10px', padding: '10px', border: '1px solid blue', width: 'calc(100% - 42px)'}}>
				Database access level: 
				<span style={{marginLeft: '15px', marginRight: '30px', color: 'blue'}}>{holder.state.dbAccessMode}</span>
				<button type='button' style={{marginLeft: '15px'}}
					onClick={()=> {holder.setDbAccessMode('readWrite')}}
				>
					Read/Write
				</button>
				<button type='button' style={{marginLeft: '15px'}}
					onClick={()=> {holder.setDbAccessMode('readOnly')}}
				>
					Read Only
				</button>
				<button type='button' style={{marginLeft: '15px'}}
					onClick={()=> {holder.setDbAccessMode('none')}}
				>
					Locked
				</button>
			</div>
		</div>
	);
}

function TabObjects(props) {
	var res = props.holder.resource;
	var task = props.holder.task;
	var listCheckBoxHtml = props.holder.listObjectClasses.map( (c,index) => {
		return (
			<div key={index}>
				<input 
					type='checkbox' name={c.class.id} checked={c.checked} 
					onChange={props.holder.handleObjectCheckBoxClick} 
				/> {c.class.id}
			</div>
		);
	} );
	return (
		<div>
			<div style={{textAlign: 'center', margin: '10px'}}>
				{res.labelRebuildObjects}
			</div>
			<div style={{display: 'flex'}}>
				<div>
					{listCheckBoxHtml}
				</div>
				<div style={{marginLeft: '100px'}}>
					<input 
						type='checkbox' name={'downloadImages'} checked={task.downloadImages} 
						onChange={props.holder.handleDownloadImagesCheckBoxClick} 
					/> {res.cbDownloadImages}
				</div>
			</div>
		</div>
	);
}

function TabCards(props) {
	var res = props.holder.resource;
	var listCheckBoxHtml = props.holder.listCardClasses.map( (c,index) => {
		return (
			<div key={index}>
				<input 
					type='checkbox' name={c.class.id} checked={c.checked} 
					onChange={props.holder.handleCardCheckBoxClick} 
				/> {c.class.id}
			</div>
		);
	} );
	return (
		<div>
			<div style={{textAlign: 'center', margin: '10px'}}>
				{res.labelRebuildCards}
			</div>
			{listCheckBoxHtml}
		</div>
	);
}

function TabLinks(props) {
	var res = props.holder.resource;
	var listCheckBoxHtml = props.holder.listLinkClasses.map( (c,index) => {
		return (
			<div key={index}>
				<input 
					type='checkbox' name={c.class.id} checked={c.checked} 
					onChange={props.holder.handleLinkCheckBoxClick} 
				/> {c.class.id}
			</div>
		);
	} );
	return (
		<div>
			<div style={{textAlign: 'center', margin: '10px'}}>
				{res.labelRebuildLinks}
			</div>
			{listCheckBoxHtml}
		</div>
	);
}

function TabFiles(props) {
	var res = props.holder.resource;
	var listCheckBoxHtml = props.holder.listFileLinkClasses.map( (c,index) => {
		return (
			<div key={index}>
				<input 
					type='checkbox' name={c.class.id} checked={c.checked} 
					onChange={props.holder.handleFileLinkCheckBoxClick} 
				/> {c.class.id}
			</div>
		);
	} );
	return (
		<div>
			<div style={{textAlign: 'center', margin: '10px'}}>
				{res.labelRebuildFiles}
			</div>
			{listCheckBoxHtml}
		</div>
	);
}

function TabWords(props) {
	var res = props.holder.resource;
	var listCheckBoxHtml = props.holder.listWordClasses.map( (c,index) => {
		return (
			<div key={index}>
				<input 
					type='checkbox' name={c.class.id} checked={c.checked} 
					onChange={props.holder.handleWordCheckBoxClick} 
				/> {c.class.id}
			</div>
		);
	} );
	return (
		<div>
			<div style={{textAlign: 'center', margin: '10px'}}>
				{res.labelRebuildWords}
			</div>
			{listCheckBoxHtml}
		</div>
	);
}

const localStyles = {
	divMainStyle: {
		width: '1000px', 
		border: '1px solid blue', 
		marginLeft: 'auto', 
		marginRight: 'auto',
		marginTop: '20px',
		marginBottom: '20px',
		padding: '10px'
	},
	divActiveTab: {
		margin: '10px 0px 0 0px', /* top | right | bottom | left */
		padding: '5px 10px 5px 10px',
		fontWeight: 'bold',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: '#f8f8f8',
		cursor: 'pointer'
	},	
	divNotActiveTab: {
		margin: '10px 0px 0 0px',
		padding: '5px 10px 5px 10px',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: 'lightgray',
		cursor: 'pointer'
	},	
};

export default DbManagerPage;
