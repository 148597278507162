import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_CardsPanel, {DIRECTION_VERTICAL} from '../../../components/plain/FieldList_CardsPanel';
import FieldList_Reference from '../../../components/plain/FieldList_Reference';
import FieldList_Enum from '../../../components/plain/FieldList_Enum';
import FieldList_MultilangString from '../../../components/plain/FieldList_MultilangString';

import {getLanguage} from '../../../util/localStorage';
import {getDefinedValue} from '../../../util/tools';
import { AUTHORITY_AUTHOR_PATH } from '../../../util/routes';
import { FORM_AUTHORITY_AUTHOR_TABLE } from '../../../util/consts/forms';


class TabBrAuthors extends React.Component {
	
	constructor(props) {
		super(props);
	}

	contributorCardBuilder = (fieldContributor, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var contriburorType = fieldContributor.value.role.value;
		var contriburorTypeText = plain.realm.getEnumerationItemText( plain.enumContributorType, contriburorType);
		if( contriburorTypeText )
			card += contriburorTypeText + ': '

		var currentLanguage = getLanguage();
		var name = null;
		for(let n of fieldContributor.value.names.value) {
			if( n.value.language.value == currentLanguage && 
					(n.value.shortName.value || n.value.fullName.value) ) 
			{
				name = n;
				break;
			}
		}
		if( name == null && fieldContributor.value.names.value.length > 0)
			name = fieldContributor.value.names.value[0];
		if( name ) {
			if( name.value.shortName.value )
				card += name.value.shortName.value;
			else if( name.value.fullName.value )
				card += name.value.fullName.value;
		}
		
		return card;
	}

	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '10px' }}>
				<FieldList_CardsPanel path={'contributor'} instance={instance} parentField={parentField} plain={plain} 
					itemComponent={ContributorPanel} itemCardBuilder={this.contributorCardBuilder}
					ignoreTitle={true}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '300px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGrey',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		);
	}
}

class ContributorPanel extends React.Component {

	refContributorAuthorityNodes = [
		{
			classId: 'AuthorityAuthor', plainFormPath: AUTHORITY_AUTHOR_PATH, 
			selectInstanceFormId: FORM_AUTHORITY_AUTHOR_TABLE,
			selectInstanceFormTitle: 'Авторитетні записи'
		}
	];
	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
		this.state = {
			contributorType: props.fieldGroup ? props.fieldGroup.value.role.value : '' 
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if( prevProps.instance !== this.props.instance ) {
			if( prevProps.instance )
				prevProps.instance.removeChangeListener( this );
			if( this.props.instance ) {
				this.props.instance.addChangeListener( this );
				this.setState({contributorType: this.props.fieldGroup ? this.props.fieldGroup.value.role.value : ''})
			}
		}
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}

	handleInstanceChange = async (event) => {
		let field = event.field;
		if( field && field.parent == this.props.fieldGroup ) {
			this.setState({contributorType: field.value});
		}
	}
	
	nameCardBuilder = (fieldName, index) => {
		var plain = this.props.plain;
		var card = '';
		var language = fieldName.value.language.value;
		if( language ) {
			var languageText = plain.realm.getEnumerationItemText( plain.enumLanguage, language);
			card += languageText + '; ';
		}
		if( fieldName.value.shortName.value )
			card += fieldName.value.shortName.value;
		else if( fieldName.value.fullName.value )
			card += fieldName.value.fullName.value;
		if( ! card )
			card = '...'
		return card;
	}
	buildNamePanel = (fieldGroup, listItemInfo) => {
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<NamePanel fieldGroup={fieldGroup} listItemInfo={listItemInfo} 
				instance={instance} plain={plain} 
			/>
		);
	}
	biographyCardBuilder = (fieldBiography, index) => {
		var plain = this.props.plain;
		var card = '';
		
		var langText = plain.realm.getEnumerationItemText( plain.enumLanguage, fieldBiography.value.language.value);
		if( langText )
			card += '['+ langText + '] ';
		
		card += getDefinedValue(fieldBiography.value.text.value, '');
		
		if( card.length > 50 ) {
			card = card.substring(0, 47) + '...';
		}
		
		return card;
	} 
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		
		let translationLanguagesHtml = null;
		let contributorType = fieldGroup ? fieldGroup.value.role.value : '';
		if( contributorType == 'B06' ) {
			translationLanguagesHtml = (
				<div style={{display: 'flex', alignItems: 'start', marginBottom: '15px'}}>
					<FieldList_Enum 
						instance={instance} path={'fromLanguage'} plain={plain} parentField={fieldGroup}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginRight: '10px'}}}
					/>
					<FieldList_Enum
						instance={instance} path={'toLanguage'} plain={plain} parentField={fieldGroup}
						labelEmptyToHeader={true}
						styles={{divMain: {flexGrow: '1', marginLeft: '10px'}}}
					/>
				</div>
			);
		}
		return (
			<div style={{flexGrow: '1', backgroundColor: 'rgb(220,220,220)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'subjectType'} parentField={fieldGroup} plain={plain}
							styles={{ select: {maxWidth: '150px', flexGrow: '1', marginRight: '20px'}}}
						/>
						<FieldEnum instance={instance} path={'role'} parentField={fieldGroup} plain={plain}
							styles={{ select: {maxWidth: '150px', flexGrow: '1', marginRight: '20px'}}}
						/>
						<FieldString instance={instance} path={'dates'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'section'} parentField={fieldGroup} plain={plain}
							styles={{ input: {maxWidth: '150px', flexGrow: '1'}}}
						 />
					</div>
					{translationLanguagesHtml}
					<FieldList_MultilangString
						instance={instance} path={'roleSpecification'} parentField={fieldGroup} plain={plain}
						labelEmptyToHeader={true} styles={{divMain: {padding: '5px', marginBottom: '15px'}}}
					/>
					<FieldList_CardsPanel path={'names'} instance={instance} parentField={fieldGroup} plain={plain} 
						buildItemComponent={this.buildNamePanel} itemCardBuilder={this.nameCardBuilder}
						direction={DIRECTION_VERTICAL} 
						styles={{
							divMain: {
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue',
								marginTop: '10px',
								marginBottom: '10px'
							},
							divCardsBlock: {
								backgroundColor: 'rgb(200,200,200)',
								minWidth: '300px'
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
							}
						}}
					/>
					<FieldList_CardsPanel path={'/biography'} instance={instance} plain={plain} parentField={fieldGroup} 
						itemComponent={BiographyPanel} itemCardBuilder={this.biographyCardBuilder}
						labelEmptyToHeader={true}
						styles={{
							divMainStyle: {
								marginTop: '10px',
								padding: '10px 10px 10px 10px',
								backgroundColor: 'rgb(200,200,200)',
								border: '1px solid blue'
							},
							divCardsBlock: {
								marginRight: '10px',
								minWidth: '300px',
								backgroundColor: 'rgb(200,200,200)',
							},
							divCard: {
								marginTop: '3px',
								padding: '7px',
								cursor: 'pointer',
								backgroundColor: 'rgb(230,230,230)',
								minHeight: '18px'
								
							}
						}}
					/>
					<FieldList_Reference path={'contributorAuthority'} instance={instance} parentField={fieldGroup} plain={plain} 
						styles={{divMain: {marginTop: '10px'}}}
						labelEmptyToHeader={true}
						refNodes={this.refContributorAuthorityNodes}
					/>
				</div>
			</div>
		)
	}
}

class BiographyPanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '0 0 0 15px', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styles={{ select: {maxWidth: '150px', marginRight: '20px'}}}
						/>
						<FieldEnum instance={instance} path={'format'} parentField={fieldGroup} plain={plain}
							styles={{ select: {flexGrow: '1'}}}
						/>
					</div>
					<FieldString instance={instance} path={'text'} parentField={fieldGroup} plain={plain}
						multiline={true} rows={4} cols={60}
						styles={{ input: {width: '98%'}}}
					 />
				</div>
			</div>
		)
	}
}

class NamePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}

	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', margin: '5px 0 0 0', padding: '10px', backgroundColor: 'rgb(240,240,240)'}}>
				<div style={{padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}}
						/>
						<FieldEnum instance={instance} path={'nameType'} parentField={fieldGroup} plain={plain}
							styles={{ select: {flexGrow: '1'}}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'keyName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'name'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '150px', flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'secondName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '150px', flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'shortName'} parentField={fieldGroup} plain={plain}
							styles={{input: {flexGrow: '0', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'fullName'} parentField={fieldGroup} plain={plain}
							styles={{input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'place'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'affilation'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrAuthors;