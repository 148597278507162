import {formatDateTime, parseDateTime} from '../tools';

const FIELD_GLOBAL_ID = 'globalId';
const FIELD_ROOT_ID = 'root';

// Data tpes
export const DATATYPE_GROUP = 'Group';
export const DATATYPE_LIST = 'List';
export const DATATYPE_STRING = 'String';
export const DATATYPE_MULTILANG_STRING = 'MultilangString';
export const DATATYPE_ENUM = 'Enum';
export const DATATYPE_INTEGER = 'Integer';
export const DATATYPE_DECIMAL = 'Decimal';
export const DATATYPE_DATETIME = 'Datetime';
export const DATATYPE_REFERENCE = 'Reference';
export const DATATYPE_FILE = 'File';

// Instance Edition Types
export const IET_SET_VALUE = 'setValue';
export const IET_ADD_LIST_ITEM = 'addItem';
export const IET_MOVE_UP_LIST_ITEM = 'moveUpItem';
export const IET_MOVE_DOWN_LIST_ITEM = 'moveDownItem';
export const IET_DELETE_LIST_ITEM = 'deleteItem';

var internalInstanceIdCounter = 1;

export const createEmptyFieldFileValue = () => {
	return {
		fileState: '', 
		fileBody: null,
		fileIndex: -1,
		imageBinary: null,
		sourceFileName: '', 
		fileURL: '', 
		storeId: 0, 
		storeGlobalId: '' 
	}
}

export class Instance {

	id = null;
	globalId = null;
	model = null;
	fieldCounter = 0;
	systemData = {
		ownerUser: null,
		ownerGroup: null,
		createDate: null,
		modifyDate: null,
		createUser: null,
		modifyUser: null,
	};
	
	fieldGlobalId = {
		model: {
			fieldId: FIELD_GLOBAL_ID,
			dataType: DATATYPE_STRING,
			subfields: []
		},
		fieldId: 'fld' + (this.fieldCounter++),
		parent: null,
		value: '' 
	};
	rootField = {
		model: {
			fieldId: FIELD_ROOT_ID,
			dataType: DATATYPE_GROUP,
			subfields: []
		},
		fieldId: 'fld' + (this.fieldCounter++),
		parent: null,
		value: {} 
	};
	
	listChangeListener = [];
	
	constructor(instanceModel, bean) {
		
		this.internalId = internalInstanceIdCounter++;
		this.model = instanceModel;
		if( bean != null ) {
			this.id = bean.id;
			this.globalId = bean.globalId;
			this.systemData.ownerUser = bean.ownerUser;
			this.systemData.ownerGroup = bean.ownerGroup;
			this.systemData.createDate = bean.createDate;
			this.systemData.modifyDate = bean.modifyDate;
			this.systemData.createUser = bean.createUser;
			this.systemData.modifyUser = bean.modifyUser;
			
			this.fieldGlobalId.value = this.globalId; 
		}
		
		for(let sfModel of instanceModel.subfields) {
			if( bean == null ) {
				var field = this.createInstanceField(sfModel, null);
			} else {
				field = this.createInstanceField(sfModel, bean[sfModel.fieldId]);
			}
			field.parent = this.rootField; 
			this.rootField.value[sfModel.fieldId] = field;
			this.rootField.model.subfields.push(sfModel);
		}
	}
	
	createInstanceField(fieldModel, beanField) {
		var field = {
			instance: this,
			model: fieldModel,
			fieldId: 'fld' + (this.fieldCounter++),
			parent: null,
			value: null
		}
		
		if( fieldModel.dataType == DATATYPE_LIST ) {
			field.value = [];
			if( beanField != null ) {
				for(let itemOfBean of beanField) {
					var item = this.createInstanceField(fieldModel.item, itemOfBean);
					item.parent = field;
					field.value.push( item );
				}
			}
		} else if( fieldModel.dataType == DATATYPE_GROUP ) {
			field.ref = {};
			if( beanField != null ) {
				field.ref.className = beanField.className;
				field.ref.id = beanField.id;
				field.ref.globalId = beanField.globalId;
			}
			field.value = {};
			for(let sfModel of fieldModel.subfields) {
				if( beanField == null ) {
					var subfield = this.createInstanceField(sfModel, null);
				} else {
					subfield = this.createInstanceField(sfModel, beanField[sfModel.fieldId]);
				}
				subfield.parent = field; 
				field.value[sfModel.fieldId] = subfield;
			}
		} else if( fieldModel.dataType == DATATYPE_MULTILANG_STRING ) {
			field.value = { lang: '', value: '' };
			if( beanField != null ) {
				field.value.lang = beanField.lang; 
				field.value.value = beanField.value; 
			}
		} else if( fieldModel.dataType == DATATYPE_FILE ) {
			field.value = createEmptyFieldFileValue();
			if( beanField != null ) {
				field.value.fileState = beanField.fileState;
				field.value.sourceFileName = beanField.sourceFileName;
				field.value.fileURL = beanField.fileURL;
				field.value.storeId = beanField.storeId;
				field.value.storeGlobalId = beanField.storeGlobalId;
			}
		} else {
			if( beanField )
				field.value = beanField;
			else if( fieldModel.initialValue )
				field.value = fieldModel.initialValue;
			else
				field.value = null;
			if( fieldModel.dataType == DATATYPE_DATETIME ) {
				if( field.value != null && field.value != '' ) {
					var d = new Date(field.value);
					field.value = formatDateTime( d );
				}
			}
		}
		
		return field;
	}
	getFieldPath(field) {
		
		if( field.model.fieldId == FIELD_GLOBAL_ID )
			return '/globalId';
		
		if( field.parent == null )
			return '/' + field.model.fieldId;
			
		if( field.parent.model.dataType == DATATYPE_LIST ) {
			let index = -1;
			for(let i=0; i < field.parent.value.length; i++) {
				if( field == field.parent.value[i] ) {
					index = i;
					break;
				}
			}
			
			return this.getFieldPath( field.parent ) + '/' + index;
		}

		return this.getFieldPath( field.parent ) + '/' + field.model.fieldId;
	}

	addChangeListener(listener) {
		this.listChangeListener.push( listener );
	}
	removeChangeListener(listener) {
		for(let i=this.listChangeListener.length-1; i >= 0; i--) {
			if( this.listChangeListener[i] === listener ) {
				this.listChangeListener.splice(i, 1);
			}
		}
	}
	
	notifyChangeListeners = async (event) => {
		console.log( "Instance changed", event, this );
		for(let listener of this.listChangeListener) {
			await listener.handleInstanceChange(event);
		}
	}

	getField(path, parentField) {
		if( path == '/globalId' )
			return this.fieldGlobalId;
		
		if( ! parentField )
			parentField = this.rootField;
		if( path.startsWith('/') )
			path = path.substring(1);
			
		let index = path.indexOf('/');
		let fieldId = '';
		if( index == -1 ) {
			fieldId = path;
			return parentField.value[fieldId];
		} else {
			fieldId = path.substring( 0, index );
			path = path.substring(index+1);
			let childField = parentField.value[fieldId];
			if( childField == null || childField === undefined )
				return null;
			return this.getField(path, childField);
		}
	}
	getSubfieldModel(fieldModel, subfieldId) {
		for(let sf of fieldModel.subfields) {
			if( sf.fieldId == subfieldId )
				return sf;
		}
		return null;
	}
	getListItemIndex(fieldItem) {
		var fieldList = fieldItem.parent;
		for(let i=0; i < fieldList.value.length; i++) {
			if( fieldList.value[i] == fieldItem )
				return i;
		}
		return -1;
	}
	getFields( filter = (field) => {return true}) {
		let listField = [];
		this.populateFields(this.rootField, listField, filter);
		return listField;
	}
	populateFields(field, listField, filter) {
		if( filter(field) )
			listField.push( field );
		if( field.model.dataType == DATATYPE_GROUP ) {
			for(let sf of Object.values(field.value) ) {
				this.populateFields(sf, listField, filter);
			}
		} else if( field.model.dataType == DATATYPE_LIST ) {
			for(let item of field.value) {
				this.populateFields(item, listField, filter);
			}
		}
	}

	setFieldValue(field, newValue) {
		var oldValue = field.value;
		field.value = newValue;
		if( field.model.fieldId == FIELD_GLOBAL_ID )
			this.globalId = newValue;
		var event = {
			eventType: IET_SET_VALUE,
			field: field,
			oldValue: oldValue,
			newValue: newValue
		}
		this.notifyChangeListeners(event);
	}
	clearField(field) {
		if( field.model.dataType == DATATYPE_GROUP ) {
			field.ref = {};
			for(let sf of Object.values(field.value) ) {
				this.clearField(sf);
			}
		} else if( field.model.dataType == DATATYPE_LIST ) {
			for(let i = field.value.length-1; i >= 0; i--) {
				this.deleteListItem(field, i);
			}
		} else if( field.model.dataType == DATATYPE_MULTILANG_STRING ) {
			this.setFieldValue(field, { lang: '', value: '' } );
		} else if( field.model.dataType == DATATYPE_FILE ) {
			this.setFieldValue(field, createEmptyFieldFileValue() );
		} else {
			this.setFieldValue(field, null );
		}
		
	}
	addListItem(fieldList, item) {
		if( item == undefined || item == null )
			item = this.createInstanceField(fieldList.model.item, null);
		item.parent = fieldList;
		fieldList.value.push( item );
		
		var event = {
			eventType: IET_ADD_LIST_ITEM,
			field: fieldList,
		}
		this.notifyChangeListeners(event);
		return item;
	}
	moveUpItem(fieldList, index) {
		if( index <= 0 )
			return;
		var prev = fieldList.value[index-1];
		fieldList.value[index-1] = fieldList.value[index];
		fieldList.value[index] = prev; 

		var event = {
			eventType: IET_MOVE_UP_LIST_ITEM,
			field: fieldList,
			itemIndex: index,
		}
		this.notifyChangeListeners(event);
	}
	moveDownItem(fieldList, index) {
		if( index >= fieldList.value.length-1 )
			return;
		var next = fieldList.value[index+1];
		fieldList.value[index+1] = fieldList.value[index];
		fieldList.value[index] = next; 

		var event = {
			eventType: IET_MOVE_DOWN_LIST_ITEM,
			field: fieldList,
			itemIndex: index,
		}
		this.notifyChangeListeners(event);
	}
	deleteListItem(fieldList, index) {
		fieldList.value.splice(index, 1);
		
		var event = {
			eventType: IET_DELETE_LIST_ITEM,
			field: fieldList,
			itemIndex: index,
		}
		this.notifyChangeListeners(event);
	}
	
	createBean() {
		var bean = {
			id: this.id,
			globalId : this.globalId,
			ownerUser: this.systemData.ownerUser,
			ownerGroup: this.systemData.ownerGroup,
			createDate: this.systemData.createDate,
			modifyDate: this.systemData.modifyDate,
			createUser: this.systemData.createUser,
			modifyUser: this.systemData.modifyUser,
		}
		
		for(let subfieldModel of this.rootField.model.subfields) {
			this.addBeanField(bean, this.rootField.value[subfieldModel.fieldId]);
		}
		
		return bean;
	}
	addBeanField(beanParent, instanceField) {
		if( instanceField.isProposal )
			return;
		if( instanceField.model.dataType == DATATYPE_LIST ) {
			beanParent[instanceField.model.fieldId] = [];
			for(let item of instanceField.value)
				this.addBeanField(beanParent[instanceField.model.fieldId], item);
		} else if( instanceField.model.dataType == DATATYPE_GROUP ) {
			let group = {};
			let ref = instanceField.ref;
			if( ref ) {
				if( ref.className )
					group.className = ref.className; 
				if( ref.id )
					group.id = ref.id; 
				if( ref.globalId )
					group.globalId = ref.globalId; 
			}
			for(let subfieldModel of instanceField.model.subfields) {
				this.addBeanField(group, instanceField.value[subfieldModel.fieldId]);
			}
			if( instanceField.parent.model.dataType == DATATYPE_LIST )
				beanParent.push( group );
			else
				beanParent[instanceField.model.fieldId] = group;
		} else {
			var value = instanceField.value;
			if( instanceField.model.dataType == DATATYPE_DATETIME ) {
				value = parseDateTime( value );
			} else if( instanceField.model.dataType == DATATYPE_FILE ) {
				// Ignore some work fields (imageBinary, fileBody, ...)
				value = {
					fileState: value.fileState, 
					fileIndex: value.fileIndex,
					sourceFileName: value.sourceFileName,
					fileURL: value.fileURL,
					storeId: value.storeId,
					storeGlobalId: value.storeGlobalId,
				};
			}
			if( instanceField.parent.model.dataType == DATATYPE_LIST )
				beanParent.push( value );
			else
				beanParent[instanceField.model.fieldId] = value;
		}
	}

}

